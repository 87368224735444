import React from 'react'
import styled from 'styled-components'
import { colorTheme } from '../../constants/colors'
import { H2 } from './TextStyle'

const Container = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  > svg {
    width: 18px;
    height: 18px;
    ${'' /* margin-right: 10px; */}
    color: ${colorTheme.white}
  }
  & ${H2}{
    color: ${colorTheme.white};
    font-size: 16px;
  }
  `

function IconText({Icon, title}) {
  return (
    <Container>
        <H2>{title}</H2>
        <Icon/>
    </Container>
  )
}

export default IconText