import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { colorTheme } from '../../../constants/colors'

const Container = styled.div`
  display:flex;
  width:150px;
  height:25px;
  align-items:center;
  margin:15px 0px 15px 15px;
  z-index:1;
  cursor:pointer;
  & :hover > h3{
    color:${colorTheme.black};
    transition: all .1s ease-in-out;
  }
  & :hover > svg{
    color: black;
    transition: all .1s ease-in-out;
  }
  `

const NavlinkStyle = styled(NavLink)`
  display:flex;
  justify-content:flex-start;
  align-items:center;
  text-decoration: none;
  height: 25px;
  width: 100%;
  > svg {
    width:18px;
    height:18px;
    margin-right:15px;
    color:${colorTheme.secondary};
    transition: all .3s ease-in-out;
  }
  &.active{
    > h3{
      color:${colorTheme.black};
    };
    >svg {
      color:${colorTheme.black};
    }
  }
`
const ItemTitle = styled.h3`
  font-size: 11pt;
  margin:0;
  padding:0;
  opacity: ${props => props.minimize ? 0 : 1};
  transition: all .3s ease-in-out;
  color:${colorTheme.secondary};
  font-weight: 600;
  letter-spacing:.5px;
  user-select:none;
`

function MenuItem({Icon, text, minimize, active, to, ...config}) {
  return (
    <Container {...config} >
      <NavlinkStyle to={to} >
        <Icon/>
        <ItemTitle minimize={minimize} active={active}>{text}</ItemTitle>
      </NavlinkStyle>
    </Container>
  )
}

export default MenuItem