import React, { useState } from "react";
import SideMenu from "../components/Navigation/SideMenu/SideMenu";
import TopBar from "../components/Navigation/TopBar/TopBar";
import styled from "styled-components";
import { Outlet } from "react-router";
import { colorTheme } from "../constants/colors";

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  box-sizing: border-box;
  padding: 10px 25px 20px 0;
  padding-left: ${(props) => (props.minimize ? "110px" : "250px")};
  background-color: ${colorTheme.secondary};
  transition: all 0.3s ease-in-out;
`;
const Content = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

function Layout({ children }) {
  const [hideMenu, setHideMenu] = useState(false);
  return (
    <>
      <SideMenu hideMenu={hideMenu} clickHandler={()=>{setHideMenu(!hideMenu)}}/>
      <Container minimize={hideMenu}>
        <TopBar />
        <Content>
          <Outlet />
          {children}
        </Content>
      </Container>
    </>
  );
}

export default Layout;
