import React, { useState } from "react";
import styled from "styled-components";
import BoxWrapper from "../../components/BoxWrapper/BoxWrapper";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import InputClear from "../../components/UI/Input/InputClear";
import ProgressBar from "../../components/UI/ProgressBar";
import SearchBar from "../../components/UI/SearchBar";
import TableStyled from "../../components/UI/TableStyled";
import Table from "../../components/UI/Table";
import { TitleSection } from "../../components/UI/TextStyle";
import { colorTheme } from "../../constants/colors";
import FormStorage from "./FormStorage";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Label,
  Legend,
  Line,
  ComposedChart,
  ResponsiveContainer,
} from "recharts";
import { useNavigate } from "react-router-dom";
import obat from '../../data/obat.json'

const GridContainer = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  /* height: 1000px; */
  position: relative;
  grid-template-areas:
    "left middle right"
    "left bottom bottom ";
  grid-template-columns: max(350px) 1fr 1.5fr;
  gap: 20px;
  overflow: auto;
  @media screen and (max-height: 767px) {
    grid-template-rows: max(200px) minmax(400px, 1fr);
  }
  @media screen and (min-height: 768px) {
    grid-template-rows: 1fr minmax(400px, 2fr);
  }
`;
const ListSubstance = styled.div`
  grid-area: left;
  position: sticky;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  & TR {
    &:nth-child(odd) {
      background-color: white;
    }
  }
  & TD {
    &:first-child {
      width: 40%;
    }
  }
`;

const Capacity = styled(BoxWrapper)`
  grid-area: middle;
  display: flex;
`;
const Usage = styled(BoxWrapper)`
  grid-area: right;
  display: flex;
  overflow: hidden;
  /* padding:10px; */
`;
const StockHistory = styled(BoxWrapper)`
  grid-area: bottom;
  display: flex;
`;
const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  column-gap: 10px;
  & Button {
    width: 100%;
    height: 35px;
    font-size: 12px;
  }
`;
const InputSearch = styled(InputClear)`
  /* width: 50px; */
`;
const TR = styled.tr`
  width: 100%;
  cursor: pointer;
  /* border-spacing: 5em; */
`;
const TD = styled.td`
  font-size: 12px;
  padding: 8px;
  vertical-align: top;
  > svg {
    width: 15px;
    margin-right: 5px;
    height: 15px;
    cursor: pointer;
    color: ${colorTheme.gray};
  }
`;
function RawStorage() {
  const column = [
    { heading: "Name", value: "name" },
    { heading: "Amount", value: "amount" },
  ];
  const columnHistory = [
    { heading: "Date", value: "date" },
    { heading: "Name", value: "name" },
    { heading: "Transaction", value: "Transaction" },
    { heading: "Amount", value: "amount" },
    { heading: "Total", value: "total" },
  ];
  const data = obat.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
  const datas = [
    {
      name: "Jan",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Feb",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Mar",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "Apr",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "May",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Jun",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "Jul",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
    {
      name: "Aug",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
    {
      name: "Sep",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
    {
      name: "Oct",
      uv: 349,
      pv: 430,
      amt: 2100,
    },
    {
      name: "Nov",
      uv: 3490,
      pv: 0,
      amt: 2100,
    },
    {
      name: "Dec",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];
  const [showForm, setShowForm] = useState(false);
  const [formType, setFormType] = useState('')
  const navigate = useNavigate()
  function showFormHandler(show, type){
    setShowForm(show);
    setFormType(type);
    console.log(show, type)
  }
  return (
    <>
      <GridContainer>
        <ListSubstance>
          <ButtonWrapper>
            <Button onClick={()=>showFormHandler(true, 'new')}>Add New</Button>
            <Button onClick={()=>showFormHandler(true, 'restock')}>Restock</Button>
            <Button onClick={()=>showFormHandler(true, 'stockout')}>Stock Out</Button>
          </ButtonWrapper>
          <BoxWrapper>
            <TitleSection>Raw List</TitleSection>
            <ButtonWrapper>
              <InputSearch
                inputColor={colorTheme.secondary}
                width="100%"
                inputType="input"
                placeholder="Search Name of substance"
                // onClickClose={() => setInputSearchValue("")}
                // onChange={(e) => setInputSearchValue(e.target.value)}
              />
              <Input 
                inputStyle="select" 
                
              />
            </ButtonWrapper>
            <TableStyled column={column}>
              {data?.map((dt, idx) => (
                <TR key={idx} onClick={()=> navigate('details', {state:dt})}>
                  {column.map((item, index) => {
                    if (item.value === "name") {
                      return (
                        <TD key={index} onClick={() => {}}>
                          {dt[item.value]}
                        </TD>
                      );
                    } else {
                      return (
                        <TD key={index}>
                          <ProgressBar
                            amount={dt.stock}
                            low={dt.low}
                            units={dt.units}
                            capacity={dt.capacity}
                          />
                        </TD>
                      );
                    }
                  })}
                </TR>
              ))}
            </TableStyled>
          </BoxWrapper>
        </ListSubstance>
        <Capacity>
          <TitleSection>Capacity</TitleSection>
        </Capacity>
        <Usage>
          <TitleSection>Usage</TitleSection>
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
              width="100%"
              height="100%"
              data={datas}
              margin={{
                top: 15,
                right: 20,
                bottom: 0,
                left: -10,
              }}
            >
              <XAxis dataKey="name" />
              <YAxis dataKey="pv" tickLine={false} axisLine={false}>
                <Label
                  value="Total Production (pcs)"
                  offset={0}
                  angle={-90}
                  position="insideLeft"
                  style={{ textAnchor: "middle" }}
                />
              </YAxis>
              <Cell />
              <Tooltip />
              <Bar dataKey="pv" fill={colorTheme.primary} stackId="a" />
              {/* <Line type="monotone" dataKey="pv" stroke="#ff7300" /> */}
              <CartesianGrid vertical={false} opacity={0.5} stroke="#DFE2E6" />
            </ComposedChart>
          </ResponsiveContainer>
        </Usage>
        <StockHistory>
          <TableStyled column={columnHistory} data={[]} />
        </StockHistory>
      </GridContainer>
      {showForm &&
        <FormStorage
          onClose={()=> showFormHandler(false, '')}
          formType={formType}
        ></FormStorage>
      }
    </>
  );
}

export default RawStorage;
