import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ModalForm from "../../components/ModalForm/ModalForm";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import dataObat from '../../data/obat.json';

const ModalStorage = styled(ModalForm)``;
const WrapperInput = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  gap: 10px;
`;

function FormStorage({ formType, ...config }) {
  const [optObat, setOptObat] = useState([])
  useEffect(()=>{
    function getOptions(){
      const obatOptions = dataObat.map((dt) => {
        return {
          value: dt.id,
          title: dt.name,
        };
      });
      setOptObat([...obatOptions])
    }
    getOptions()
  })
  return (
    <ModalStorage
      title={
        formType === "new"
          ? "Add New Stock"
          : formType === "restock"
          ? "Restock"
          : "Stock Out"
      }
      {...config}
    >
      {formType === "new" ? (
        <Input title="Name" inputStyle="input" errorText="" />
      ) : (
        <WrapperInput>
          <Input 
            title="Name" 
            inputStyle="select" 
            errorText="" 
            options={optObat}

          />
          <Input
            title="Date"
            inputStyle="date"
            errorText=""
            value=""
          />
        </WrapperInput>
      )}
      {formType === "new" && (
        <WrapperInput>
          <Input 
            title="Max Capacity" 
            inputStyle="input" 
            errorText="" 
          />
          <Input
            title="Min Capacity (for Alert)"
            inputStyle="input"
            errorText=""
          />
        </WrapperInput>
      )}
      <WrapperInput>
        <Input title="Amount" inputStyle="input" errorText="" />
        <Input
          title="Units"
          inputStyle="input"
          errorText=""
          value=""
          disabled={formType === "new" ? false : true}
        />
      </WrapperInput>
      
      <WrapperInput>
        <Button>
          {formType === "new"
            ? "Add New"
            : formType === "restock"
            ? "Restock"
            : "Stock Out"}
        </Button>
      </WrapperInput>
    </ModalStorage>
  );
}

export default FormStorage;
