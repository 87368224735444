import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  ${'' /* html {
    font-size: 10px;
  } */}
  html, body {
    font-size: 10px;
    font-family: 'Open Sans', sans-serif;
    margin: 0;
  }
  h2 {
    font-weight:700;
    font-size:14pt;
  }
`;

export default GlobalStyle;