import React from "react";
import styled from "styled-components";
import { colorTheme } from "../../constants/colors";
import Pagination from "../Pagination/Pagination";
import Spinner from "../Loading/Spinner";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
  position: relative;
  z-index: 4;
  &::-webkit-scrollbar {
    display: block;
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${colorTheme.gray};
    border-radius: 6px;
    border-right: none;
    border-left: none;
  }

  &::-webkit-scrollbar-track-piece:end {
    background-color: transparent;
    margin-bottom: 10px;
  }

  &::-webkit-scrollbar-track-piece:start {
    background-color: transparent;
    margin-top: 40px;
  }
`;

const TableComponent = styled.table`
  width: 100%;
  border-spacing: 0;
`;
const TBody = styled.tbody`
  & TR {
    width: 100%;
    &:nth-child(odd) {
      background-color: #f2f2f2;
    }
  }
  & TD {
    font-size: 12px;
    padding: 8px;
    > svg {
      width: 15px;
      margin-right: 5px;
      height: 15px;
      cursor: pointer;
      color: #7a7a7a;
    }
  }
`;
const THead = styled.thead`
`;

const TH = styled.th`
  font-size: 12px;
  text-align: left;
  padding: 8px;
  font-weight: 600;
  color: ${colorTheme.gray};
  top: 0;
  z-index: 1;
  position: -webkit-sticky;
  position: sticky;
  background-color: ${colorTheme.white};
`;

const THAction = styled(TH)`
  width:50px;
  `;

const TR = styled.tr`
  width: 100%;
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

function TableStyled({
  column,
  numbering,
  actions,
  loading,
  children,
  pagination,
  perPageValue,
  onChangeSelect,
  options,
  arrNumber,
  totalPage,
  selectedPage,
  clicked,
  clickNext,
  clickPrev,
}) {
  return (
    <>
      <Container>
        <TableComponent>
          <THead>
            <TR>
              {column?.map((dt, index) => {
                return <TH key={index}>{dt.heading}</TH>;
              })}
              {actions !== undefined && actions.length > 0 ? (
                <THAction>Actions</THAction>
              ) : null}
            </TR>
          </THead>
          <TBody>
            {children}
          </TBody>
        </TableComponent>
        {loading !== undefined && loading === true && <Spinner transparent />}
      </Container>
      {pagination !== undefined ? (
        <Pagination
          inputStyle="select"
          value={perPageValue}
          onChange={onChangeSelect}
          options={options}
          arrNumber={arrNumber}
          totalPage={totalPage}
          active={selectedPage}
          clicked={clicked}
          clickNext={clickNext}
          clickPrev={clickPrev}
        />
      ) : null}
    </>
  );
}

export default TableStyled;
