import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { colorTheme } from "../../constants/colors";
import { H1, H3, Text, H4 } from "../../components/UI/TextStyle";
import LetterHead from "../../components/LetterHead/LetterHead";
import BoxWrapper from "../../components/BoxWrapper/BoxWrapper";
import Button from "../../components/UI/Button";
import TableStyled from "../../components/UI/TableStyled";
import { getDataAPI } from "../../utility/http";
import { useLocation, useNavigate } from "react-router-dom";
import { formatToIDR } from "../../utility/inputFormatter";
import { FaEdit, FaImage, FaPrint, FaTrash } from "react-icons/fa";
import Confirmation from "../../components/Modal/Confirmation";
import Input from "../../components/UI/Input";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  & ${BoxWrapper}{
    height: fit-content;
  }
`;

const WrapperColumn = styled.div`
  display: flex;
  column-gap: 20px;
`;

const GridContainer = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  /* max-width: 1000px; */
  position: relative;
  & ${BoxWrapper} {
    overflow: hidden;
    position: relative;
  }
  & ${Text} {
    font-size: 11px;
  }
  grid-template-areas:
    "header header detail"
    "info info ."
    "content content content"
    "note note price"
    "button button button";
  grid-template-columns: 1fr, 1fr, 1f;
  grid-template-rows: 100px max-content min(300px) max(100px) max(60px);
`;

const Header = styled.div`
  grid-area: header;
  display: flex;
  padding-bottom: 20px;
  box-sizing: border-box;
`;

const Detail = styled.div`
  grid-area: detail;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  > ${H1} {
    color: ${colorTheme.primary};
    font-size: 20px;
    font-weight: 700;
  }
  & ${Text} {
    color: ${colorTheme.gray};
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 5px;
  }
`;

const Info = styled.div`
  grid-area: info;
  max-width: 70%;
`;

const Content = styled.div`
  display: flex;
  grid-area: content;
  padding-top: 5px;
  border-bottom: 1px solid ${colorTheme.gray};
  & th:nth-child(7), th:nth-child(8){
    text-align: right;
  }
  & tr td:nth-child(7), tr td:nth-child(8), tr td:nth-child(6) {
    text-align: right;
  }
  & tr td:nth-child(4) {
    min-width: 100px;
  }
  & th:nth-child(9){
    text-align: center;
  }
  & tr td:nth-child(9) {
    display: flex;
    justify-content: center;
    & div {
      width: 120px;
    }
    & select {
      background-color: ${colorTheme.primary};
      color: ${colorTheme.secondary};
    }
  }
`;

const Note = styled.div`
  grid-area: note;
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding-top: 10px;
`;

const Price = styled.div`
  grid-area: price;
  padding-top: 10px;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  & ${Text} {
    font-size: 13px;
    margin: 2.5px 0;
  }
  & ${Text}:last-child {
    font-size: 16px;
    color: ${colorTheme.primary};
    font-weight: 600;
  }
`;

const ButtonWrapper = styled.div`
  grid-area: button;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 20px;
  /* height: 100px;
  padding-bottom: 20px; */
`;

const SpanNote = styled.span`
  font-style: italic;
  font-weight: 400;
`;

const TR = styled.tr``;
const TD = styled.td``;

const OrderDetails = React.forwardRef(({ onBack, onSubmit, data }) => {
  const column = [
    { heading: "No", value: "no" },
    { heading: "Image", value: "image" },
    { heading: "Article Name & Description", value: "articleName" },
    { heading: "Process", value: "process" },
    { heading: "Qty", value: "qty" },
    { heading: "Price", value: "price" },
    { heading: "Price/article", value: "priceArticle" },
    { heading: "Subtotal", value: "total" },
    { heading: "Status", value: "status" },
  ];
  const [orderData, setOrderData] = useState(null);
  const [statusOpt, setStatusOpt] = useState(null);
  const [customerData, setCustomerData] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [total, setTotal] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    async function getData() {
        try{
            const response = await getDataAPI(`/order/number/${location.pathname.split("/").pop()}`)
            const customerId = response.data.Data.customer_id
            const resCustomer = await getDataAPI(`/customer/${customerId}`)
            const responseStatus = await getDataAPI(`/order_status`)
            setCustomerData(resCustomer.data.Data)
            setOrderData(response.data.Data)
            const statusOptions = responseStatus.data.Data.map((dt) => {
              return {
                value: parseInt(dt.id),
                title: dt.name,
              };
            });
            setStatusOpt(statusOptions)
        }
        catch (err){
            console.log(err)
        }
    }
    getData();
  }, []);
  function onChangeStatusHandler(event, idx){
    const idValue = event.target.value
    const nameValue = event.nativeEvent.target[idx].text
    const dataOrder = {...orderData}
    dataOrder.articles[idx].status_id = idValue
    dataOrder.articles[idx].status_name = nameValue
    setShowConfirmModal(true)
    setOrderData(dataOrder)
  }
  return (
    <>
    <Container>
      <BoxWrapper>
      <GridContainer >
        <Header>
          <LetterHead />
        </Header>
        <Detail>
          <H1>Purchase Order</H1>
          <Text>{orderData?.number}</Text>
          <Text>{new Date(orderData?.date).toLocaleDateString('en-GB')}</Text>
        </Detail>
        <Info>
          <H3>Customer Information</H3>
          <Text>
            {customerData?.name}
          </Text>
          <Text>{customerData?.phone} - {customerData?.other_phone}</Text>
          <Text>{customerData?.address_line}</Text>
        </Info>
        <Content>
          <TableStyled column={column} numbering={true}>
            {orderData?.articles.map((dt, idx) => {
              return(
              <TR key={idx}>
                <TD>{idx+1}</TD>
                <TD>
                  <FaImage/>
                </TD>
                <TD>
                  <H4>{dt.name}</H4>
                  <SpanNote>{dt.note}</SpanNote>
                </TD>
                <TD>
                  {
                    dt.services?.map(key => {
                      return (<Text key={key.id}>{key.service_name} <br/></Text>)
                  })}
                </TD>
                <TD>{dt.quantity + " " + dt.unit_name}</TD>
                <TD>
                  {
                    dt.services?.map(key => {
                      return (<Text key={key.id}>{formatToIDR(key.amount)} <br/></Text>)
                  })}
                </TD>
                <TD>{formatToIDR(dt.services.reduce((n, {amount}) => n + amount, 0))}</TD>
                <TD>{formatToIDR(dt.amount)}</TD>
                <TD>
                  <Input
                    inputStyle='select'
                    options={statusOpt}
                    value={dt.status_id}
                    onChange={(event) => onChangeStatusHandler(event, idx)}
                  />
                </TD>
              </TR>
            )})}
          </TableStyled>
        </Content>
        <Note>
          <Text>
            Order by: {orderData?.order_by} <br/>
            Status: {orderData?.status_name}
          </Text>
        </Note>
        <Price>
            <Text>Grand Total: {orderData !== null && formatToIDR(orderData?.amount)}</Text>
        </Price>
        <ButtonWrapper>
            <WrapperColumn>
            <Button btnStyle='secondaryDanger'> <FaTrash/> Delete</Button>
            <Button btnStyle='secondary' onClick={()=> navigate('edit', {state: {number: location.state.number, customer: customerData}})}><FaEdit/>Edit</Button>
            </WrapperColumn>
            <WrapperColumn>
              <Button><FaPrint/>Print</Button>  
              <Button onClick={()=> navigate(-1)}>Close</Button>
            </WrapperColumn>
        </ButtonWrapper>
      </GridContainer>
      </BoxWrapper>
    </Container>
    {
      showConfirmModal &&
      <Confirmation
        title='Are You Sure ?'
        desc='By confirming this action will permanently change it from database, you cannot undo this change'
        onConfirm={()=>{setShowConfirmModal(false)}}
        onCancel={()=>{setShowConfirmModal(false)}}
      />
  }
    </>
  );
});

export default OrderDetails;
