import styled from "styled-components";
import { colorTheme } from "../../constants/colors";

const BoxWrapper = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:flex-start;
  width:${props=>props.width};
  height: 100%;
  background-color:${colorTheme.white};
  border-radius:10px;
  padding:25px;
  box-sizing:border-box;
  overflow:auto;
`
export default BoxWrapper