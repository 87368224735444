import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ModalForm from "../../components/ModalForm/ModalForm";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import { colorTheme } from "../../constants/colors";
import { postDataAPI, postProduct, updateDataAPI, updateProduct } from "../../utility/http";
import { formatInputCurrency, formatToIDR } from "../../utility/inputFormatter";
import { checkValidity } from "../../utility/inputValidation";

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: ${(props) => props.width};
  gap: 0px 40px;
  justify-content: space-between;
  > label {
    color: ${colorTheme.primary};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 10px;
  column-gap: 10px;
`;
function AddProduct({ onClose, onSuccess, data }) {
  const initialStateProduct = {
    name: {
      value: "",
      status: false,
      errorText: "",
      validation: { required: true },
    },
    price: {
      value: 0,
      status: false,
      errorText: "",
      validation: { required: true },
    },
    service_code: {
      value: "",
      status: false,
      errorText: "",
      validation: { required: false },
    },
    description: {
      value: "",
      status: false,
      errorText: "",
      validation: { required: false },
    },
  };
  const [productValues, setProductValues] = useState(initialStateProduct);
  const [loading, setLoading] = useState(false);
  const [formEditMode, setFormEditMode] = useState(false);
  const [statusResponse, setStatusResponse] = useState(null);
  const [responseMessage, setResponseMessage] = useState("");
  const [enableButton, setEnableButton] = useState(false)

  useEffect(() => {
    async function getData() {
      if (data !== null) {
        setFormEditMode(true);
        const { id, is_actived, ...restValues } = data;
        Object.keys(restValues).forEach((key) => {
          let value = data[key];
          if (key === "price") {
            value = formatToIDR(data[key]);
          }
          setProductValues((prevState) => ({
            ...prevState,
            [key]: {
              ...prevState[key],
              value: value,
            },
          }));
        });
      }
    }
    getData();
  }, [data]);

  useEffect(()=> {
    function inputDataChange(){
        if(formEditMode && data !== null){
            const { id, is_actived, ...restValues } = data;
            let dataChanged = true
            Object.keys(restValues).forEach(key => {
                let valueInput = productValues[key].value
                if(key === 'price'){
                    valueInput = parseInt(productValues[key].value.replace(/[^0-9]/g, ""))
                }
                dataChanged = data[key] === valueInput && dataChanged
            })
            setEnableButton(true)
        }
    }
    inputDataChange()
  },[productValues])

  function onChangeInput(event, objName) {
    if (objName === "active") {
      setProductValues((prevState) => ({
        ...prevState,
        [objName]: {
          ...prevState[objName],
          value: !productValues.active.value,
        },
      }));
    } else {
      let value =
        objName === "price"
          ? formatInputCurrency(event.target.value)
          : event.target.value;
      setProductValues((prevState) => ({
        ...prevState,
        [objName]: {
          ...prevState[objName],
          value: value,
          status: false,
          errorText: "",
        },
      }));
    }
  }
  async function onClickSubmit(editMode) {
    let valid = true;
    const { id, ...restProductValues } = productValues;
    const objKeys = Object.keys(editMode ? restProductValues : productValues);
    objKeys.forEach((key) => {
      const validation = checkValidity(
        productValues[key].value,
        productValues[key].validation
      );
      valid = validation.valid && valid;
      setProductValues((prevState) => ({
        ...prevState,
        [key]: {
          ...prevState[key],
          status: validation.valid,
          errorText: validation.text,
        },
      }));
    });
    if (valid) {
      setLoading(true);
      const productObj = {
        name: productValues.name.value,
        price: parseInt(productValues.price.value.replace(/[^0-9]/g, "")),
        description: productValues.description.value,
        service_code: productValues.service_code.value,
        ...(editMode && { id: data.id }),
      };
      const responseProduct = editMode ? updateDataAPI('/service', productObj) : postDataAPI('/service', productObj)
        await responseProduct.then((res) => {
          setTimeout(()=>{
            setStatusResponse(true);
            if (res.status === 200) {
              setResponseMessage(res.data.Messages);
              setTimeout(() => {
                setLoading(false);
                setStatusResponse(null);
                onClose();
                onSuccess();
              }, 1500);
            }
          }, 1500)
        }).catch((error) => {
          console.log(error)
          setResponseMessage(error.response.data.Messages)
          if(error.response.status === 500){
            setStatusResponse(false)
            setProductValues(prevState => ({
                ...prevState,
                name: {
                    ...prevState.name,
                    errorText:'Nama sudah digunakan',
                    status: false
                }
            }))
          }
        });
    }
  }
  return (
    <ModalForm 
      title={formEditMode ? "Edit Service" : "Add Service"} 
      onClose={onClose}
      showLoading={loading}
      responseMessages={responseMessage}
      responseStatus={statusResponse}
      >
      <Input
        inputStyle="input"
        title="Name*"
        placeholder="Enter product or service name"
        value={productValues.name.value}
        errorText={productValues.name.errorText}
        onChange={(event) => onChangeInput(event, "name")}
      />
      <Wrapper>
        <Input
          inputStyle="input"
          title="Price*"
          pattern="[0-9]*"
          min={0}
          placeholder="Enter the price"
          value={productValues.price.value}
          errorText={productValues.price.errorText}
          onChange={(event) => onChangeInput(event, "price")}
        />
        <Input
          inputStyle="input"
          title="Service Code"
          placeholder="Enter ID of Product"
          value={productValues.service_code.value}
          errorText={productValues.service_code.errorText}
          onChange={(event) => onChangeInput(event, "service_code")}
        />
      </Wrapper>
      <Input
        inputStyle="textArea"
        title="Description"
        placeholder="Enter the description of product or service"
        value={productValues.description.value}
        errorText={productValues.description.errorText}
        onChange={(event) => onChangeInput(event, "description")}
      />
      <ButtonWrapper>
        {formEditMode ? (
          <>
            <Button width="100px" onClick={onClose}>
              Cancel
            </Button>
            <Button
              btnStyle="secondary"
              width="100px"
              onClick={() => onClickSubmit(true)}
              disabled = {!enableButton}
            >
              Save
            </Button>
          </>
        ) : (
          <Button width="100px" onClick={() => onClickSubmit(false)}>
            Submit
          </Button>
        )}
      </ButtonWrapper>
    </ModalForm>
  );
}

export default AddProduct;
