import React from 'react'
import { FaChevronRight } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import Notification from '../../Notification/Notification'
import { H1, Text } from '../../UI/TextStyle'
import User from '../../User/User'


const Container = styled.div`
  display:flex;
  width:100%;
  justify-content:space-between;
  align-items:center;
  margin: 10px 0;
  `
const PageWrapper = styled.div`
  display:flex;
  flex-direction:column;
  & ${H1}{
    /* margin-top: 20px; */
  }
  `
const Wrapper = styled.div`
  display: flex;
`

function TopBar() {
  const pageTitle = useSelector(state => state.layout.title)
  return (
    <Container>
      <PageWrapper>
        <Text>Test <FaChevronRight /> Order</Text>
        <H1>{pageTitle}</H1>
      </PageWrapper>
      <Wrapper>
        <Notification />
        <User />
      </Wrapper>
    </Container>
  )
}

export default TopBar