import { createSlice } from "@reduxjs/toolkit"

const initialStateOrder = {orderInfo:{}}

const orderSlice = createSlice({
    name:'order',
    initialState: initialStateOrder,
    reducers:{
        submitOrder(state, action){
            state.orderInfo = action.payload
        }
    }
})

export const orderActions = orderSlice.actions

export default orderSlice.reducer