import { createSlice } from "@reduxjs/toolkit"

const initialStateLayout = {title: 'Hello'}

const layoutSlice = createSlice({
    name:'layout',
    initialState: initialStateLayout,
    reducers:{
        changeTitle(state, action){
            state.title = action.payload
        }
    }
})

export const layoutActions = layoutSlice.actions

export default layoutSlice.reducer