import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { colorTheme } from '../../constants/colors';
import Pagination from '../Pagination/Pagination';
import { FaPen, FaTrash } from 'react-icons/fa';
import Spinner from '../Loading/Spinner';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow:auto;
     /* &::-webkit-scrollbar-track-piece:end {
    margin-bottom: 10px; 
    };
    &::-webkit-scrollbar-track-piece:start {
    margin-top: 50px;
    } */
    /* &::-webkit-scrollbar{
        width: 15px;
        margin-top: 10px;
        padding-top: 10px;
        background-color: red;
    } */
    &::-webkit-scrollbar {
        display: block;
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: ${colorTheme.gray};
        border-radius: 6px;
        border-right: none;
        border-left: none;
    }

    &::-webkit-scrollbar-track-piece:end {
        background-color: transparent;
        margin-bottom: 10px; 
    }

    &::-webkit-scrollbar-track-piece:start {
        background-color: transparent;
        margin-top: 40px;
    }
`

const TableComponent = styled.table`
  width: 100%;
  /* height: 100%; */
  border-spacing: 0;
  /* &::-webkit-scrollbar-track-piece:end {
    margin-bottom: 10px; 
    };
    &::-webkit-scrollbar-track-piece:start {
    margin-top: 50px;
    } */
    
  `
const TBody = styled.tbody`
  `
const THead = styled.thead`
`
const TH = styled.th`
    font-size: 12px;
    text-align: left;
    padding: 8px;
    font-weight: 600;
    color: ${colorTheme.black};
    top: 0;
    z-index: 0; 
    position: -webkit-sticky;
    position: sticky;
    background-color: ${colorTheme.white};
`
const THAction = styled(TH)`
  width:50px;
  `;
  
const TR = styled.tr`
    width: 100%;
    cursor:${props => props.rowPointer ? 'pointer' : 'context-menu' } ;
    &:nth-child(odd){
        background-color: #F2F2F2;
    }
  `
const TD = styled.td`
    font-size: 12px;
    padding: 8px;
    > svg {
        width: 15px;
        margin-right: 5px;
        height: 15px;
        cursor: pointer;
        color:#7A7A7A;
    }
    `
function Table({
    column,
    data,
    actions,
    onClickRow,
    onClickEdit,
    onClickDelete,
    startNumber,
    loading,
    pagination,
    perPageValue,
    onChangeSelect,
    options,
    arrNumber,
    totalPage,
    selectedPage,
    clicked,
    clickNext,
    clickPrev }) {
    const [edit, setEdit] = useState(false)
    const [erase, setErase] = useState(false)
    const [dataList, setDataList] = useState([])
    useEffect(() => {
        function getAction() {
            if (actions !== undefined && actions.length > 0) {
                actions.map(dt => {
                    if (dt === 'delete') {
                        setErase(true)
                    }
                    if (dt === 'edit') {
                        setEdit(true)
                    }
                    else {
                        return null
                    }
                })
            } else {
                setEdit(false)
                setErase(false)
            }
        }
        getAction()
    }, [actions])
    useEffect(()=>{
        function dataTable(){
            setDataList([...data])
        }
        dataTable()
    },[data])
    return (
        <>
            <Container>
                {/* <H2>Title</H2> */}
                <TableComponent>
                    <THead>
                        <TR>
                            <TH>No.</TH>
                            {column?.map((dt, index) => {
                                return <TH key={index}>{dt.heading}</TH>
                            })}
                            {actions !== undefined ?
                                <THAction>Actions</THAction> : null
                            }
                        </TR>
                    </THead>
                    <TBody>
                        {dataList?.map((dt, idx) => (
                            <TR rowPointer={onClickRow !== undefined} key={idx}>
                                {startNumber === undefined ?
                                    <TD>{idx + 1}</TD>
                                    :
                                    <TD>{startNumber + idx + 1}</TD>
                                }
                                {
                                    column.map((item, index) => {
                                        return <TD key={index} onClick={() => onClickRow !== undefined && onClickRow({idx:idx, ...dt})}>{dt[item.value]}</TD>
                                    })
                                }
                                {
                                    actions !== undefined &&
                                        <TD>
                                            {edit ? <FaPen onClick={() => onClickEdit({idx, ...dt})} /> : null}
                                            {erase ? <FaTrash onClick={() => onClickDelete({idx, ...dt})} /> : null}
                                        </TD>
                                }
                            </TR>
                        ))}
                    </TBody>
                </TableComponent>
            </Container>
            {
                loading !== undefined && loading === true ?
                    <Spinner visible={null} bgTransparent={true}/>
                    : null
            }
            {
                pagination !== undefined && pagination === true ?
                    <Pagination
                        inputStyle='select'
                        value={perPageValue}
                        onChange={onChangeSelect}
                        options={options}
                        arrNumber={arrNumber}
                        totalPage={totalPage}
                        active={selectedPage}
                        clicked={clicked}
                        clickNext={clickNext}
                        clickPrev={clickPrev}
                    />
                    : null
            }
        </>
    )
}

export default Table