import React, { useEffect, useState } from "react";
import { FaImage } from "react-icons/fa";
import { MdAdd } from "react-icons/md";
import styled from "styled-components";
import ModalForm from "../../components/ModalForm/ModalForm";
import BoxWrapper from "../../components/BoxWrapper/BoxWrapper";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import SearchBar from "../../components/UI/SearchBar";
import Table from "../../components/UI/Table";
import { H3, H4, Label, Text } from "../../components/UI/TextStyle";
import { colorTheme } from "../../constants/colors";
import { formatIDRtoInt, formatToIDR } from "../../utility/inputFormatter";
import { checkValidity } from "../../utility/inputValidation";
import FormItem from "./FormItem";
import { getDataAPI, postDataAPI } from "../../utility/http";
import FormCustomer from "../Customer/FormCustomer";
import { useLocation, useNavigate } from "react-router-dom";
import Spinner from "../../components/Loading/Spinner";
import history from "../../history";

const Container = styled.div`
  display: grid;
  height: 100%;
  width: 100%;
  gap: 20px;
  overflow: auto;
  grid-template-columns: 1fr minmax(230px, 280px);
  grid-template-rows: 1fr auto;
  grid-template-areas:
    "left right"
    "left bottom";
`;

const GridLeft = styled(BoxWrapper)`
  grid-area: left;
  & Button {
    align-self: flex-end;
  }
`;

const GridRight = styled(BoxWrapper)`
  grid-area: right;
  overflow: unset;
`;

const GridBottom = styled.div`
  grid-area: bottom;
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
  & Button{
    width: 100%;
  }
`;
const InputImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
`;
const InfoWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
  > H4 {
    min-width: 180px;
    max-width: 250px;
    min-height: 60px;
  }
`;

const PreviewImage = styled.div`
  display: flex;
  height: 150px;
  width: 100%;
  margin-top: 20px;
  border: 1px solid ${colorTheme.secondary};
  border-radius: 5px;
  background-color: ${colorTheme.secondary};
  box-sizing: border-box;
  overflow: hidden;
  margin-right: 20px;
  justify-content: center;
  align-items: center;
  > svg {
    width: 100px;
    height: 100px;
    color: ${colorTheme.white};
    padding: 5px;
  }
  > img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
`;

const PreviewImageForm = styled(PreviewImage)`
  height: 100px;
  width: 100px;
  margin-bottom: 20px;
  padding: 0;
  > svg {
    width: 50px;
    height: 50px;
    color: ${colorTheme.white};
    /* padding: 5px; */
  }
`;

const SpanData = styled.span`
  font-size: 12px;
  font-weight: 400;
  ${"" /* color:${colorTheme.primary} */}
`;
const WrapperInput = styled.div`
  width: 250px;
`;
const ImgPreview = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
const PreviewImageItem = styled.div`
  width: 500px;
  height: 500px;
`;

const TotalPrice = styled(H3)`
  display: flex;
  flex-direction: column;
  color:${colorTheme.primary};
  align-self: flex-end;
  & Label{
    color: ${colorTheme.black};
    align-self: flex-end;
  }
`;

const ErrorTable = styled(Text)`
  color: ${colorTheme.red};
`

function FormOrder() {
  const optionsTypeOrder = [
    {
      value: "Production",
      title: "Production",
    },
    {
      value: "Sample",
      title: "Sample",
    },
  ];

  const initialStateCust = {
    id: "",
    name: "",
    phone: "",
    other_phone: "",
    email: "",
    address_line: "",
  };

  const initialStateOrder = {
    date_order: {
      value: "",
      status: false,
      errorText: "",
      validation: { required: false },
    },
    type_order: {
      value: "",
      status: false,
      errorText: "",
      validation: { required: true },
    },
    note: {
      value: "",
      status: false,
      errorText: "",
      validation: { required: false },
    }
  }

  const column = [
    { heading: "Article Name", value: "name" },
    { heading: "Image", value: "image" },
    { heading: "Process", value: "services" },
    { heading: "Qty", value: "quantity" },
    { heading: "Unit", value: "unit" },
    { heading: "Price", value: "articlePrice" },
    { heading: "Subtotal", value: "subtotal" },
    { heading: "Note", value: "note" },
  ];
  const [data, setData] = useState([]);
  const [customerValues, setCustomerValues] = useState(initialStateCust);
  const [orderValues, setOrderValues] = useState(initialStateOrder);
  const [inputSearch, setInputSearch] = useState("");
  const [itemTable, setItemTable] = useState([]);
  const [newItemTable, setNewItemTable] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showAddItem, setShowAddItem] = useState(false);
  const [showPreviewImage, setShowPreviewImage] = useState(false);
  const [showCustomerForm, setShowCustomerForm] = useState(false);
  const [imgPreview, setImgPreview] = useState(null);
  const [productsData, setProductData] = useState(null);
  const [loadingInputSearch, setLoadingInputSearch] = useState(false)
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [responseStatus, setResponseStatus] = useState(null);
  const [errorCustomer, setErrorCustomer] = useState('');
  const [errorTable, setErrorTable] = useState('');
  const [selectedImageItem, setSelectedImageItem] = useState(null);
  const [editData, setEditData] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [blockRoute, setBlockRoute] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  function selectedValHandler(values) {
    Object.keys(initialStateCust).map((key) => {
      setCustomerValues((prevState) => ({
        ...prevState,
        [key]: values[key],
      }));
    });
  }

  function onCloseForm() {
    setShowAddItem(false);
    setSelectedImageItem(null);
    setSelectedImage(null);
    setEditData({});
  }
  
  // function onClickEditItem() {
  //   const tableData = itemTable.filter(
  //     (item) => item.articleName !== editData.articleName
  //   );
  //   const validation = formValidation(tableData);
  //   if (validation.valid) {
  //     const tableUpdated = itemTable.map((dt, index) => {
  //       if (index === editData.idx) {
  //         dt = validation.values;
  //       }
  //       return dt;
  //     });
  //     setItemTable(tableUpdated);
  //     setShowAddItem(false);
  //     setItemValues(initialStateItem);
  //     setEditData({});
  //   }
  // }
  
  useEffect(()=>{
    async function getData(){
      if(location.state !== null){
        setEditMode(true)
        Object.keys(customerValues).forEach(key=> {
          setCustomerValues(prevState => ({
            ...prevState,
            [key]: location.state.customer[key]
          }))
        })
        try{
          const response = await getDataAPI(`/order/number/${location.state.number}`)
          console.log(response)
          const dataItem = response.data.Data.articles.map(dt => {
            return{
              name: dt.name,
              path_image: dt.path_image,
              note: dt.note,
              quantity: dt.quantity,
              services: dt.Services
            }
          })
          setItemTable(dataItem)
        }
        catch (err){
            console.log(err)
        }
      }else{
        setEditMode(false)
        try {
          const response = await getDataAPI(`/customer?name=`);
          let valData = response.data.Data !== null ? response.data.Data : [];
          setData(valData);
        } catch (err) {
          console.log(err);
        }
      }
    }
    getData()
  },[])

  useEffect(() => {
    let unblock
    if (blockRoute) {
      unblock = history.block((tx) => {
        let url = tx.location.pathname;
        if (window.confirm(`You have unsaved work that will be lost if you leave this page.`)) {
          unblock();
          tx.retry();
        }
      });
    }
    return () => {
      if (unblock === "function") {
        unblock();
      }
    };
  }, [blockRoute]);

  useEffect(()=>{
    function detectTouched(){
      let isNotNull = true
      Object.keys(orderValues).map(key => {
        isNotNull = orderValues[key].value !== '' && isNotNull
      })
      if(itemTable.length > 0 || customerValues.id !== '' || isNotNull){
        setBlockRoute(true)
      } else {
        setBlockRoute(false)
      }
    }
    detectTouched()
  },[itemTable, customerValues, orderValues])

  useEffect(()=>{
    async function newItemsHandler() {
      if(itemTable.length > 0){
        setErrorTable('')
      }
      try{
        const response = await getDataAPI('/service?status=active');
        const dataService = response.data.Data
        setProductData(dataService)
        const newItems = itemTable.map((dt) => {
          let pricePerArticle = 0;
          const newArrService = dt.services.map(val => {
            console.log(val)
            pricePerArticle += typeof val.amount !== 'number' ? formatIDRtoInt(val.amount) : val.amount
            return dataService.find(key=> key.id === parseInt(val.service_id)).name
          })
          return {
            ...dt, 
            services: newArrService.join(', '),
            articlePrice: pricePerArticle,
            subtotal: pricePerArticle * dt.quantity
          }
        });
        setNewItemTable(newItems)
      }
      catch (err){
        console.log(err)
        setNewItemTable([])
      }
    }
    newItemsHandler()
  },[itemTable])

  function showTableItem(values){
    const items = values.map(dt => {
      return{
        ...dt,
        articlePrice: formatToIDR(dt.articlePrice),
        subtotal:formatToIDR(dt.subtotal)
      }
    })
    return items
  }

  function handlerEditItem(value) {
    setShowAddItem(true);
    const itemsData = itemTable[value.idx];
    setEditData({ ...itemsData, idx: value.idx });
    setSelectedImageItem(itemsData.image);
  }

  function handlerDeleteItem(e) {
    const arrItems = [...itemTable];
    arrItems.splice(e.idx, 1);
    setItemTable(arrItems);
  }

  function previewImageHandler(idx) {
    setShowPreviewImage(true);
    setImgPreview(itemTable[idx].image);
    return idx;
  }

  async function onChangeSearchHandler(value) {
    setErrorCustomer('')
    if (inputSearch !== "") {
      setLoadingInputSearch(true)
      try {
        const response = await getDataAPI(`/customer?name=${value}`);
        let valData = response.data.Data !== null ? response.data.Data : [];
        setData(valData);
        setLoadingInputSearch(false)
      } catch (err) {
        console.log(err);
        setLoadingInputSearch(false)
      }
    }
    setInputSearch(value);
  }

  function onChangeOrder(event, key) {
    const value = event.target.value
    setOrderValues((prevState) => ({
        ...prevState,
        [key]: {
          ...prevState[key],
          value: value,
          status: false,
          errorText: "",
        },
      }));
    }

  async function onSuccessCreateCustomer(data){
    setShowCustomerForm(false)
  }

  async function onClickSubmit(){
    let isValid = true
    isValid = customerValues.id !== "" && itemTable.length > 0
    Object.keys(orderValues).forEach((key) => {
      const validation = checkValidity(
        orderValues[key].value,
        orderValues[key].validation
      );
      if(customerValues.id === ''){
        setErrorCustomer('Customer belum dipilih')
      }
      if(itemTable.length < 1){
        setErrorTable('Item tidak boleh kosong')
      }
      isValid = validation.valid && customerValues.id !== '' && itemTable.length > 0 && isValid;
      
      setOrderValues((prevState) => ({
        ...prevState,
        [key]: {
          ...prevState[key],
          status: validation.valid,
          errorText: validation.text,
        },
      }));
    });
    if(isValid){
      setLoading(true)
      const submitData = {
        customer_id: customerValues.id,
        customer_name: customerValues.name,
        note: orderValues.note.value,
        type: orderValues.type_order.value,
        amount: newItemTable.reduce((n, {subtotal}) => n + subtotal, 0),
        articles: newItemTable.map((dt, idx) => {
          return{
            name: dt.name,
            quantity: dt.quantity,
            unit_id: parseInt(dt.units),
            amount: dt.subtotal,
            note: dt.note,
            services: itemTable[idx].services.map(val => {
              return{
                service_id: parseInt(val.service_id),
                service_name: productsData.find(key=> key.id === parseInt(val.service_id)).name,
                amount: formatIDRtoInt(val.amount)
              }
            })
          }
        })
      }
      try{
        const responsePost = await postDataAPI('/order', submitData)
        setResponseMessage(responsePost.data.Messages)
        if(responsePost.status === 200){
          setResponseStatus(true)
          setTimeout(()=>{
            setLoading(false)
            setResponseStatus(null)
            navigate('/order')
          }, 2500)
        }
      }
      catch (err){
        console.log(err)
        setResponseStatus(false)
        setTimeout(()=>{
          setLoading(false)
          setResponseStatus(null)
        },[2500])
      }
    }
  }
  return (
    <Container>
      <GridLeft>
        <H3>{!editMode ? 'Create New Order' : 'Edit Order'}</H3>
        <WrapperInput>
          {!editMode && <SearchBar
            selectedValue={(id) => selectedValHandler(id)}
            onClickClose={()=>setCustomerValues(initialStateCust)}
            placeholder="Input customer name or id . ."
            data={data}
            onChangeInput={(e) => onChangeSearchHandler(e) }
            createButton={true}
            dropDown={true}
            errorText={errorCustomer}
            onClickCreate={() => setShowCustomerForm(true)}
            loading={loadingInputSearch}
          />
          }
        </WrapperInput>
        <InfoWrapper>
          <H4>
            Customer Name <br />{" "}
            <SpanData>
              {customerValues.name !== "" ? customerValues.name : "-"}
            </SpanData>
          </H4>
          <H4>
            Phone <br />{" "}
            <SpanData>
              {customerValues.phone !== "" ? customerValues.phone : "-"}
            </SpanData>
          </H4>
          <H4>
            Phone 2 <br />{" "}
            <SpanData>
              {customerValues.other_phone !== ""
                ? customerValues.other_phone
                : "-"}
            </SpanData>
          </H4>
          <H4>
            Email <br />{" "}
            <SpanData>
              {customerValues.email !== "" ? customerValues.email : "-"}
            </SpanData>
          </H4>
          <H4>
            Address <br />{" "}
            <SpanData>
              {customerValues.address_line !== ""
                ? customerValues.address_line
                : "-"}
            </SpanData>
          </H4>
        </InfoWrapper>
        <Button onClick={()=> setShowAddItem(true)}>
          <MdAdd />
          Add Item
        </Button>
        <H3>Table Articles</H3>
        <ErrorTable>{errorTable}</ErrorTable>
        <Table
          column={column}
          data={showTableItem(newItemTable)}
          actions={["delete", "edit"]}
          onClickDelete={(e) => handlerDeleteItem(e)}
          onClickEdit={(e) => handlerEditItem(e)}
        />
        {/* <Label>Estimated Price</Label> */}
        <TotalPrice>
          <Label>Estimated Price</Label>
          {formatToIDR(newItemTable.reduce((n, {subtotal}) => n + subtotal, 0))}
        </TotalPrice>
      </GridLeft>
      <GridRight>
        <H3>Order Details</H3>
        <Input
          inputStyle="date"
          title="Date Order"
          format="DD-MM-YYYY"
          value={orderValues.date_order.value}
          errorText={orderValues.date_order.errorText}
          onChange={(event) => onChangeOrder(event, "date_order")}
        />
        <Input
          inputStyle="select"
          title="Type Order*"
          // placeholder='John@example.com'
          options={optionsTypeOrder}
          value={orderValues.type_order.value}
          errorText={orderValues.type_order.errorText}
          onChange={(event) => onChangeOrder(event, "type_order")}
        />
        <PreviewImage>
          {selectedImage === null ? (
            <FaImage />
          ) : (
            <img src={URL.createObjectURL(selectedImage)} alt="" />
          )}
        </PreviewImage>
        <InputImage>
          <Input
            inputStyle="file"
            title="Surat Jalan (max file size 250KB)"
            name="Emp_Img"
            accept="image/png, image/jpeg, image/webp"
            errorText=""
            onChange={(event) => setSelectedImage(event.target.files[0])}
          />
          {/* {photoUrl !== "" && <FaCheckCircle />} */}
          {/* <ButtonUpload onClick={uploadImage}>Upload</ButtonUpload> */}
        </InputImage>
        <Input
          inputStyle="textArea"
          title="Notes"
          value={orderValues.note.value}
          errorText={orderValues.note.errorText}
          onChange={(event) => onChangeOrder(event, "note")}
          />
      </GridRight>
      <GridBottom>
        <Button onClick={()=> navigate(-1)}>Cancel</Button>
        <Button onClick={onClickSubmit}>Submit</Button>
      </GridBottom>
      {showCustomerForm && (
        <FormCustomer
          onClose={() => setShowCustomerForm(false)}
          onSuccess={onSuccessCreateCustomer}
          data={null}
        ></FormCustomer>
      )}
      {showAddItem && (
        <FormItem
          onClose={onCloseForm}
          data={()=>{}}
          editData={editData}
          itemData={(data) => setItemTable(prevState => [...prevState, data])}
        />
      )}
      {showPreviewImage && (
        <ModalForm
          onClose={() => {
            setShowPreviewImage(false);
            setImgPreview(null);
            URL.revokeObjectURL(imgPreview);
          }}
        >
          <PreviewImageItem>
            <ImgPreview src={URL.createObjectURL(imgPreview)} alt="" />
          </PreviewImageItem>
        </ModalForm>
      )}
      {
        loading && 
        <Spinner
          transparent
          responseStatus={responseStatus}
          messages={responseMessage}
        />
      }
    </Container>
  );
}



export default FormOrder;
