import React from 'react'
import styled from 'styled-components'
import { Text } from '../../components/UI/TextStyle'
import { colorTheme } from '../../constants/colors'
import IconText from '../UI/IconText'

const Container = styled.div`
  display:flex;
  width:100%;
  height:100%;
  flex-direction: column;
  background-color:${colorTheme.primary};
  border-radius:10px;
  /* justify-content:${props => props.desc !== '' ? 'space-between' : 'center'} ; */
  justify-content: space-between;
  align-items:flex-start;
  box-sizing:border-box;
  padding:10px;
  cursor:pointer;
  position:relative;
  /* box-shadow: 1px 3px 8px #9999; */
  & div:first-child{
    width:100%;
    justify-content:space-between;
    > h2{
      font-size:12px;
      color:${colorTheme.secondary}
    };
    > svg{
      width:15px;
      height:15px;
    }
  }
  & ${Text} {
    height:100%;
    width:40%;
    font-size:12px;
    color:${colorTheme.secondary}
  };
  & svg {
    width: 12px;
    height: 12px;
    color:${colorTheme.secondary}
  }
  `
const Wrapper = styled.div`
  display:flex;
  width: 100%;
  justify-content: flex-end;
  & ${Text}{
    width: fit-content;
    font-size: 16px;
    font-weight: 600;
    color:${colorTheme.secondary}
  };`

const SelectedBox = styled.div`
  height:50%;
  width:3px;
  position:absolute;
  left:-7px;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius:10px;
  background-color:${colorTheme.primary};
  `

function StatusBox({ Icon, title, number, desc, onClick, active }) {
  return (
    <Container onClick={onClick} desc={desc}>
      <IconText Icon={Icon !== undefined ? Icon : null} title={title} />
      <Wrapper>
        <Text>{number}</Text>
      </Wrapper>
    </Container>
  )
}

export default StatusBox