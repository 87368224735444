export const checkValidity = (value, rules, data, key) => {
  let isValid = true;
  let isErrorText = ''
  if (!rules) {
    return true
  }
  if (rules.required) {
    if (value !== '' && value !== 0 && isValid) {
      isValid = true
    } else {
      isValid = false
      isErrorText = 'Tidak boleh kosong'
    }
  }
  if (rules.isNumeric) {
    const pattern = /^\d+$/;
    isValid = pattern.test(value) && isValid
  }
  if (rules.min !== undefined) {
    isValid = parseInt(value) > rules.min && isValid
    isErrorText = isValid ? '' : 'Harus lebih dari ' + rules.min
  }
  if (rules.isEmail) {
    if(value !== ''){
      const pattern = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      isValid = pattern.test(value) && isValid
      isErrorText = isValid ? '' :'Format email tidak valid'
    }
  }
  if (rules.notSame) {
    let test = true
    data.forEach(dt => {
      if (dt[key] === value) {
        test = false
        isErrorText = 'Nama/ID sudah digunakan'
      }
    })
    isValid = test && isValid
  }

  if(rules.maxFileSize !== undefined){
    isValid = value.size < rules.maxFileSize && isValid
  }

  return { valid: isValid, text: isErrorText };
}