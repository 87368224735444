import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import Button from '../../components/UI/Button'
import {H1, H2, Text} from '../../components/UI/TextStyle'
import { colorTheme } from '../../constants/colors'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 100px 200px;
    width: 100vw;
    height: 100vh;
    background-color: ${colorTheme.secondary};
    & H1 {
        font-size: 80px;
        font-weight: bold;
        margin: -20px 0px;
    }
    & H2 {
        font-size: 50px;
        font-weight: bold;
    }
    & Button{
        margin-top: 50px;
        width: 150px;
    }
`

function ErrorPage() {
    const navigate = useNavigate()
  return (
    <Container>
        <H1>404</H1>
        <H2>Page not found</H2>
        <Text>We’re sorry, the page you requested could not be found</Text>
        <Button onClick={()=> navigate('/')}>Go to homepage</Button>
    </Container>
  )
}

export default ErrorPage