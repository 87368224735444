import React from 'react'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import styled from 'styled-components'
import Input from '../UI/Input'
import { Text } from '../UI/TextStyle'
import { colorTheme } from '../../constants/colors'

const Container = styled.div`
  display:flex;
  width:100%;
  justify-content:space-between;
  margin-top:15px;
  & div:nth-child(1){
    width:75px;
  }
`

const NumberPage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > svg {
    width:20px;
    height:20px;
    padding:5px;
    box-sizing:border-box;
    margin:0 5px;
    cursor: pointer;
  }
`
const Number = styled(Text)`
  font-size: 15px;
  padding: 5px 10px;
  box-sizing: border - box;
  color:${props => props.active ? colorTheme.secondary : colorTheme.gray};
  background-color:${props => props.active ? colorTheme.primary : 'none'};
  border-radius: 10px;
  cursor: pointer;
  user-select: none;
`
function Pagination({ arrNumber, totalPage, active, clicked, clickPrev, clickNext, ...config }) {
  return (
    <Container>
      <Input
        {...config}
      />
      <NumberPage>
        {active > 0 ? <FaChevronLeft onClick={clickPrev}/> : null}
        <Number active={active === 0} onClick={() => clicked(0)}>1</Number>
        {
          active > 3 && arrNumber.length > 5 ?
            <Number>...</Number> : null
        }
        {
          arrNumber.length > 5 ?
            active < arrNumber.length - 3 ?
              arrNumber.slice(active < 4 ? 1 : active - 2, active < 3 ? 4 : active + 3).map(key => {
                return <Number key={key} active={active === key} onClick={() => clicked(key)}>{key + 1}</Number>
              }) :

              arrNumber.slice(arrNumber.length - 5, - 1).map(key => {
                return <Number key={key} active={active === key} onClick={() => clicked(key)}>{key + 1}</Number>
              }) :
            arrNumber.slice(1, - 1).map(key => {
              return <Number key={key} active={active === key} onClick={() => clicked(key)}>{key + 1}</Number>
            })
        }
        {
          active < arrNumber.length - 4 && arrNumber.length > 5 ?
            <Number>...</Number> : null
        }
        <Number active={active === arrNumber?.length - 1} onClick={() => clicked(arrNumber?.length - 1)}>{totalPage}</Number>
        {active < arrNumber.length - 1 ? <FaChevronRight onClick={clickNext} /> : null}
      </NumberPage>
    </Container>
  )
}

export default Pagination