import React, { useState } from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'
import { FaAngleDown, FaSearch } from 'react-icons/fa'
import styled from 'styled-components'
import { colorTheme } from '../../constants/colors'
import Button from '../UI/Button'

const FilterBox = styled.div`
  display:flex;
  flex-direction:column;
  transition: all .3s ease-in-out;
  max-height:${props => props.show ? '500px' : '40px'};
  margin-bottom:10px;`

const Wrapper = styled.div`
  display:flex;
  justify-content:space-between;
  & Button {
      margin: 5px 0;
      > svg {
          margin-right:5px;
    }
  }`
const SearchWrapper = styled.div`
  display:flex;
  align-items: center;
  & Button {
    margin: 5px 0;
    > svg {
        margin-left:5px;
        transform:rotate(${props => props.show ? '-180deg' : '0deg'});
        transition: all .3s ease-in-out;
    }
  }`

const FilterInput = styled.div`
  display: flex;
  background-color: ${colorTheme.white};
  border-radius: 10px;
  padding: 10px 25px;
  width:100%;
  flex-direction:row;
  gap:0px 30px;
  align-items:flex-end;
  margin-top:10px;
  flex-wrap:wrap;
  box-sizing:border-box;
  > div {
    min-width:100px;
    max-width:150px;
  }
  > Button {
    margin: 5px 0;
    height:20px;
    > svg {
        margin-right:5px;
    }
  }`
const Input = styled.input`
  width: 100%;
  height: 35px;
  padding: 5px 15px;
  box-sizing: border-box;
  border-radius: 5px;
  border:none;
  font-weight:400;
  font-size: 10pt;
  font-family: Open Sans;
  background-color:${colorTheme.white};
  &:focus{
      outline:none;
  } 
  &:disabled{
      color:#5CB1EE;
  }`

const InputWrapper = styled.div`
  display: flex;
  width: 300px;
  justify-content: center;
  align-items: center;
  height: 35px;
  margin-right: 15px;
  background-color: ${colorTheme.white};
  border-radius: 5px;
  > svg {
      width: 20px;
      height: 20px;
      margin-right: 15px;
      cursor: pointer;
      color: ${colorTheme.gray};
    }
`
function FilterSearch({ children, onClickAdd, addTitle, onChangeSearch, searchValue, placeholder, onKeyDown }) {
  const [showFilter, setShowFilter] = useState(false)
  const [filterHeight, setFilterHeight] = useState(0)
  const ref = useRef()

  useEffect(() => {
    setFilterHeight(ref.current.clientHeight)
  }, [])

  return (
    <FilterBox show={showFilter} height={filterHeight}>
      <Wrapper>
        <SearchWrapper show={showFilter}>
          <InputWrapper>
            <Input onChange={onChangeSearch} placeholder={placeholder} value={searchValue} disabled={showFilter} onKeyDown={onKeyDown}/>
            <FaSearch/>
          </InputWrapper>
          <Button onClick={() => setShowFilter(!showFilter)} >Filter <FaAngleDown /></Button>
        </SearchWrapper>
        <Button onClick={onClickAdd}>{addTitle}</Button>
      </Wrapper>
      <FilterInput show={showFilter} ref={ref}>
        {children}
        <Button><FaSearch /> Search</Button>
      </FilterInput>
    </FilterBox>
  )
}

export default FilterSearch