import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { MdDashboard, MdShoppingCart } from "react-icons/md";
import {
  FaWarehouse,
  FaTruck,
  FaUsers,
  FaBox,
  FaCog,
  FaChartLine,
  FaUserTie,
} from "react-icons/fa";
import MenuItem from "./MenuItem";
import { useLocation } from "react-router";
import { useDispatch } from "react-redux";
import { layoutActions } from "../../../store/layout";
import { colorTheme } from "../../../constants/colors";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: ${props => props.height}px;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  user-select: none;
  transition: width 0.3s ease-in-out;
  margin-bottom: 25px;
`;

const HighlightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  top: ${(props) => props.position - 5}px;
  right: 0;
  left: 0;
  bottom: 0;
  transition: all 0.3s ease-in-out;
`;
const Highlight = styled.div`
  width: ${(props) => (props.minimize ? "60px" : "200px")};
  height: 35px;
  margin: 0;
  background-color: ${colorTheme.secondary};
  border-radius: 25px 0 0 25px;
  transition: all 0.3s ease-in-out;
`;
const ImageCurved = styled.img`
  height: 35px;
`;

function MenuList({ minimize }) {
  const [highlightPos, setHighlightPos] = useState();
  const [menuActive, setMenuActive] = useState(0);
  const location = useLocation();
  const dispatch = useDispatch();

  const listMenu = [
    { id: "", text: "Dashboard", icon: MdDashboard },
    { id: "customer", text: "Customer", icon: FaUsers },
    { id: "order", text: "Order", icon: MdShoppingCart },
    { id: "product", text: "Product", icon: FaBox },
    { id: "shipping", text: "Shipping", icon: FaTruck },
    { id: "storage", text: "Storage", icon: FaWarehouse },
    { id: "sales", text: "Sales", icon: FaChartLine },
    { id: "employee", text: "Employee", icon: FaUserTie },
    { id: "settings", text: "Settings", icon: FaCog },
  ]

  useEffect(() => {
    const pathname = location.pathname.split("/")[1];
    const position = listMenu.findIndex((dt) => dt.id === pathname);
    const titlePage = listMenu.find((dt) => dt.id === pathname);
    dispatch(layoutActions.changeTitle(titlePage?.text));
    highlightHandler(position);
  }, [menuActive, dispatch, location]);

  function highlightHandler(numberItem) {
    setHighlightPos(numberItem * 55);
    setMenuActive(numberItem);
  }

  return (
    <>
      <Container minimize={minimize} height={(listMenu.length + 1) * 55}>
        <HighlightWrapper position={highlightPos}>
          <ImageCurved src={require("../../../assets/curved-top.png")} />
          <Highlight minimize={minimize}></Highlight>
          <ImageCurved src={require("../../../assets/curved-bot.png")} />
        </HighlightWrapper>
        {listMenu.map((dt, index) => (
          <MenuItem
            key={index}
            to={"/" + dt.id}
            onClick={() => highlightHandler(index)}
            active={menuActive === index}
            minimize={minimize}
            Icon={dt.icon}
            text={dt.text}
          />
        ))}
      </Container>
    </>
  );
}

export default MenuList;
