import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
    background-color:rgba(0,0,0,0.7);
    padding:50px;
    box-sizing: border-box;
    position: absolute;
    top:0;
    left:0;
    z-index: 4;
`

function Modal({children}) {
  return (
    <Container>
        {children}
    </Container>
  )
}

export default Modal