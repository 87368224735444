import React, { useEffect, useState } from "react";
import { FaSearch, FaTimes } from "react-icons/fa";
import styled from "styled-components";
import { colorTheme } from "../../../constants/colors";
import Input from "../Input";

const InputWrapper = styled.div`
  display: flex;
  width: ${props=> props.width !== undefined ? props.width : '300px'};
  justify-content: center;
  align-items: center;
  height: 35px;
  margin-right: 15px;
  background-color: ${props=> props.color !== undefined ? props.color : colorTheme.white};
  border-radius: 5px;
  & input {
    background-color: ${props=> props.color !== undefined ? props.color : colorTheme.white};
  }
  > svg {
      width: 20px;
      height: 20px;
      margin-right: 15px;
      cursor: pointer;
      color: ${colorTheme.gray};
      
    }
`
function InputClear({onClickClose,onClickSearch, onChange, inputColor, width, ...config }) {
    const [showClose, setShowClose] = useState(false)
    const [inputValue, setInputValue] = useState('')
    useEffect(()=>{
        function changeValueHandler(){
            if(inputValue !== ''){
                setShowClose(true)
            }else{
                setShowClose(false)
            }
        }
        changeValueHandler()
    },[inputValue])
    function onChangeHandler(e){
        setInputValue(e.target.value)
        onChange(e)
    }
    function onClickCloseHandler(){
        setInputValue('')
        onClickClose()
    }
  return (
    <InputWrapper color={inputColor} width={width}>
        <Input 
            inputStyle='input'
            onChange={(e)=>onChangeHandler(e)}
            value={inputValue}
            toggleValue
            {...config}
            />
        {showClose ?
            <FaTimes onClick={onClickCloseHandler}/> :
            <FaSearch onClick={onClickSearch}/>
        }
    </InputWrapper>
  );
}

export default InputClear;
