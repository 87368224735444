import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { colorTheme } from '../../constants/colors'
import { Label } from './TextStyle'

const Wrapper = styled.div`
    & ${Label}{
        user-select: none;
    }
`
const Slider = styled.div`
    display: flex;
    width: 30px;
    height: 16px;
    background-color: ${props => props.active ? colorTheme.primary : colorTheme.gray};
    border-radius: 20px;
    padding: 0 2.5px;
    box-sizing: border-box;
    align-items: center;
    padding: 0 10px;
    box-sizing: border-box;
    justify-content: ${props => props.active ? 'flex-start' : 'flex-end'};
    cursor: pointer;
    position: relative;
    & Label{
        color: ${colorTheme.secondary};
    }
`
const Dot = styled.div`
    position: absolute;
    display: flex;
    left: ${props => props.active ? '15px' : '5px'};
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${props => props.active ? colorTheme.white : colorTheme.secondary};
    transition: all .3s ease-in-out;
    /* transform: translateX( ${props => props.active ? '100%' : '0%'}); */
    cursor: pointer;
`
function SliderRadio({ value, onClickChange, label }) {
    const [selected, setSelected] = useState(false)
    useEffect(() => {
        setSelected(value)
    }, [value])
    return (
        <Wrapper>
            {
                label !== undefined &&
                <Label>Status : {selected ? 'Active' : 'Inactive'}</Label>
            }
            <Slider active={selected} onClick={onClickChange}>
                {/* <Label>{selected ? 'On' : 'Off'}</Label> */}
                <Dot active={selected} />
            </Slider>
        </Wrapper>
    )
}

export default SliderRadio