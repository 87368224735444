import React from "react";
import styled from "styled-components";
import FilterSearch from "../../components/FilterSearch/FilterSearch";
import BoxWrapper from "../../components/BoxWrapper/BoxWrapper";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { colorTheme } from "../../constants/colors";

const Container = styled(BoxWrapper)`
  ${"" /* border-radius: 0 10px 10px 10px; */}
`;

function Production() {
  const data = [
    {
      name: "January",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Ferbruary",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "March",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "April",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "May",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "June",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "July",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
    {
      name: "August",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
    {
      name: "September",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
    {
      name: "October",
      uv: 349,
      pv: 430,
      amt: 2100,
    },
    {
      name: "November",
      uv: 3490,
      pv: 0,
      amt: 2100,
    },
    {
      name: "December",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];
  return (
    <>
      <FilterSearch></FilterSearch>
      <Container>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart width={40} height={40} data={data}>
            <XAxis dataKey="name" />
            <YAxis />
            <Cell/>
            <Tooltip />
            <Bar dataKey="pv" fill={colorTheme.primary} />
          </BarChart>
        </ResponsiveContainer>
      </Container>
    </>
  );
}

export default Production;
