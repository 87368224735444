import { ghsCorrosion, ghsToxic, ghsExplosive, ghsFlammable, ghsGas, ghsHealth, ghsOxidizer, ghsEnvironmental, ghsExclamation } from "../assets"

export const ghs = [
    {
      "id":"1",
      "name": "Corrosion",
      "icon": ghsCorrosion,
      "desc": "Corrosive to metals. Can cause chemical bums to the skin and eye damage"
    },
    {
      "id":"2",
      "name": "Toxic",
      "icon": ghsToxic,
      "desc": "Acute toxicity"
    },
    {
      "id":"3",
      "name": "Environmental",
      "icon": ghsEnvironmental,
      "desc": "Hazards to aquatic, and other environments"
    },
    {
      "id":"4",
      "name": "Exclamation",
      "icon": ghsExclamation,
      "desc": "Substances absorbed through skin or respiration and can cause damage to human health"
    },
    {
      "id":"5",
      "name": "Gas",
      "icon": ghsGas,
      "desc": "Including compressed, disolved, and liquified gases"
    },
    {
      "id":"6",
      "name": "Explosive",
      "icon": ghsExplosive,
      "desc": "Substances and mixtures that are explosive or self-reactive"
    },
    {
      "id":"7",
      "name": "Flammable",
      "icon": ghsFlammable,
      "desc": "Gases, aerosols, liquids, and solids that are flammable"
    },
    {
      "id":"8",
      "name": "Oxidizier",
      "icon": ghsOxidizer,
      "desc": "Chemicals that can oxidize and lose electrons"
    },
    {
      "id":"9",
      "name": "Health Hazzard",
      "icon": ghsHealth,
      "desc": "Chemicals that cause damage to one or more organs when inhaled"
    }
  ]