import React, { useEffect } from 'react'
import { FaExclamationTriangle } from 'react-icons/fa'
import styled from 'styled-components'
import { colorTheme } from '../../constants/colors'
import { Label } from './TextStyle'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
   
`
const ProgressWrapper = styled.div`
    position: relative;
    height: 22px;
    width: 100%;
    background-color: ${colorTheme.secondary};
    transition: all .3s ease-in-out;
    overflow: hidden;
`
const Bar = styled.div`
    position: absolute;
    background-color: ${colorTheme.primary};
    height: 100%;
    width: ${props => `${props.progressBar}%`};
    top: 0;
    left: 0;
`
const NumberLabel = styled(Label)`
    /* position: absolute; */
    display: flex;
    align-self: flex-end;
`
const Wrapper = styled.div`
    display: flex;
    > svg{
        color:${colorTheme.yellow};
        width:16px;
        height:16px;
        margin: 0px 5px;
        position: absolute;
        top:2px;
        left:-30px;
    }
`
const Amount = styled.span`
    color: ${colorTheme.primary};
`


function ProgressBar({capacity, amount, low, units}) {
  return (
    <Container>
        <Wrapper>
            {amount <= low && <FaExclamationTriangle/>}
            <ProgressWrapper capacity={capacity}>
                <Bar progressBar={amount/capacity*100}></Bar>
            </ProgressWrapper>
        </Wrapper>
        <NumberLabel><Amount>{amount}</Amount>{"/" + capacity + ' ' + units}</NumberLabel>
    </Container>
  )
}

export default ProgressBar