import React from "react";
import styled from "styled-components";
import MenuList from "./MenuList";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import { colorTheme } from "../../../constants/colors";

const Container = styled.div`
  display: flex;
  height: 100vh;
  width: ${(props) => (props.minimize ? "80px" : "220px")};
  background-color: ${colorTheme.primary};
  ${"" /* justify-content: space-around; */}
  align-items: center;
  transition: all 0.3s ease-in-out;
  flex-direction: column;
  padding: 30px 0px;
  position: fixed;
`;

const MinimizeButton = styled.div`
  display: flex;
  width: 100%;
  height: 30px;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  > svg {
    height: 22px;
    width: 22px;
    color: ${colorTheme.secondary};
    cursor: pointer;
  }
`;
const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
  width: 100%;
  height: 100px;
`;
const Logo = styled.img`
  width: 100%;
  height: 30px;
  object-fit: contain;
`;
const MenuWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  /* width */
  &::-webkit-scrollbar {
    width: 5px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    margin: 25px;
    margin-bottom: 35px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${colorTheme.gray};
    border-radius: 5px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`

function SideMenu({ hideMenu, clickHandler }) {
  return (
    <Container minimize={hideMenu}>
      <MinimizeButton>
        {hideMenu ? (
          <MdArrowForwardIos onClick={clickHandler} />
        ) : (
          <MdArrowBackIos onClick={clickHandler} />
        )}
      </MinimizeButton>
      <LogoWrapper>
        <Logo src={require("../../../assets/circle.png")} />
      </LogoWrapper>
      <MenuWrapper>
        <MenuList minimize={hideMenu} />
      </MenuWrapper>
    </Container>
  );
}

export default SideMenu;
