import React from "react";
import styled, { css, keyframes } from "styled-components";
import BoxWrapper from "../BoxWrapper/BoxWrapper";


const showAnimation = `
  opacity .1s .2s ease-in-out, 
    visibility .1s .2s ease-in-out, 
    padding .1s ease-in-out, 
    margin .3s ease-in-out, 
    max-height .1s ease-in-out ;
`
const hideAnimation = `
    opacity .1s ease-in-out, 
    visibility .1s ease-in-out, 
    padding .3s ease-in-out, 
    margin .1s .3s ease-in-out, 
    max-height .3s ease-in-out ;
`

const Container = styled(BoxWrapper)`
  display: flex;
  flex-direction: row;
  background-color: white;
  max-height: ${props => props.showFilter ? '200px' : '0px' };
  opacity: ${props => props.showFilter ? 1 : 0};
  visibility: ${props => props.showFilter ? 'visible' : 'hidden' };
  margin-top: ${props => props.showFilter ? '5px' : '0px'};
  padding: ${props => props.showFilter ? '20px' : '0px' };
  transition: ${props => props.showFilter ? showAnimation : hideAnimation };
  overflow: hidden;
  gap: 15px;
  flex-wrap: wrap;
  align-items:flex-end;
`;

function FilterBox({ children, show }) {
  return (
    <Container showFilter={show}>
        {children}
    </Container>
    );
}

export default FilterBox;
