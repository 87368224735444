export function formatDate(date) {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

export function formatStringNumber(value){
    const numberPattern = /\d+/g;
    const matchingInput = value.match(numberPattern)
    let valueNumber = matchingInput !== null ? matchingInput.join('') : ''
    return valueNumber
}

export function formatInputCurrency(value) {
    let newValue = formatStringNumber(value)
    if(newValue !== ''){
        return parseInt(newValue).toLocaleString('id', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 })
    } else {
        return ''
    }
}

export function formatToIDR(value){
    return value.toLocaleString('id', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 })
}

export function formatIDRtoInt(value){
    return parseInt(value.replace(/[^0-9]/g, ""))
}

