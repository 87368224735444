import React from 'react'
import styled from 'styled-components'
import { H1, Text } from '../UI/TextStyle'

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items: center;
`
const Span = styled.span`

`
const P = styled(Text)`
    display: flex;
`
const Image = styled.img`
    height:85%;
    margin-right: 15px;
`
const Info = styled(Container)`
    flex-direction: column;
    align-items: flex-start;
    > h1 {
        font-size: 15pt;
        font-weight: 700;
    }
    > p {
        font-size:8.5pt;
        > span {
            margin-left: 15px;
        }
    }
`
function LetterHead() {
    return (
        <Container>
            <Image src={require('../../assets/circle-logo.png')} />
            <Info>
                <H1>Circle Corporation</H1>
                <P>Jl. Jalan raya no.1, Kota Bandung, Jawa Barat 12345</P>
                <P>WA : 0812345678<Span>Phone : 022 - 5432120 / 022 - 5400000</Span></P>
                <P>Email : Dummy@gmail.com<Span>Web : www.dummy.com</Span></P>
            </Info>
        </Container>
    )
}

export default LetterHead