import React, { useState } from "react";
import { MdCheckCircle, MdError } from "react-icons/md";
import styled, {keyframes} from "styled-components";
import { colorTheme } from "../../constants/colors";
import Button from "../UI/Button";
import { H2, H3, Text } from "../UI/TextStyle";

const ringAnimation = keyframes`
 0% {transform: rotate(0deg); }
 100% { transform: rotate(1000deg); }
`
const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.bgTransparent ? 'rgba(255, 255, 255, 0.60)' : colorTheme.white};
    position: absolute;
    z-index: 5;
    top:0;
    left:0;
    right:0;
    bottom:0;
    & H3 {
        margin-left: 10px;
        color: ${colorTheme.primary};
    }
`
const Ring = styled.div`
  display: inline-block;
  width: 80px;
  height: 80px;
  margin-bottom: 30px;
  &:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid ${colorTheme.primary};
    border-color: ${colorTheme.primary} transparent ${colorTheme.primary} transparent;
    animation: ${ringAnimation} 2s linear infinite;
  }
`;

const Box = styled.div`
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    transition: all .3s ease-in-out;
    & ${H2}{
        color:${props => props.color};
    };
    & ${Text}{
        max-width: 80%;
        margin-top: 10px;
        text-align: center;
        font-size: 14px;
        color:${props => props.color};
    }
    > svg{
        width:90px;
        height:90px;
        color:${props => props.color};
    }
    > Button{
        margin: 40px 10px 10px 10px;
    }
`
function Spinner({ transparent, messages, responseStatus }) {
return(
    <Container bgTransparent={transparent}>
        {
            responseStatus === null || responseStatus === undefined ? 
            <><Ring/><H3>Loading...</H3></>:
            responseStatus ?
            <Box color={colorTheme.primary}>
                <MdCheckCircle />
                <H2>Success</H2>
                <Text>{messages}</Text>
            </Box>:
            <Box color={colorTheme.red}>
                <MdError />
                <H2>Error</H2>
                <Text>{messages}</Text>
            </Box>
        }
    </Container>
)
}

export default Spinner;
