import React, {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import { Text } from '../UI/TextStyle'
import {MdLogout, MdSettings} from "react-icons/md"

const Container = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:flex-start;
    background-color: white;
    padding: 5px 20px 5px 5px;
    border-radius: 50px;
    min-width:150px;
    box-sizing:border-box;
    transition: all .3s ease-in-out;
    position:relative;`

const Picture = styled.div`
    width:33px;
    height:33px;
    border-radius:100%;
    overflow:hidden;
    /* margin-right:20px; */
    `

const UserImage = styled.img`
    width:100%;
    height:100%;
    object-fit:cover;`

const PageWrapper = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:flex-start;
    user-select:none;
    > ${Text}:nth-child(1){
        font-weight: 700;
        font-size: 12px;
        color: #101010;
    };
    > ${Text}:nth-child(2){
        font-weight: 400;
        color: #101010;
        font-size: 12px;
    };
    `
const UserSetting = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:flex-start;
    user-select:none;
    max-width: 150px;
    position:absolute;
    border-radius:15px;
    top: calc(100% + 5px);
    right:0;
    background-color:white;
    cursor:pointer;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    padding: 10px 5px;
    box-sizing: border-box;
    transition: all .3s ease-in-out;
    z-index:2;
    > p {
        display:flex;
        align-items: center;
        width:100%;
        padding: 10px 10px;
        box-sizing: border-box;
        border-radius: 5px;
        font-weight: 600;
        > svg {
            height:18px;
            width:18px;
            margin-right: 5px;
        };
        &:hover{
            color:white;
            background-color: #5CB1E3;
        }
    }`

const UserInfo = styled(PageWrapper)`
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    align-items:center;
    width: 100%;
    /* height: 20px;; */
    cursor:pointer;
    `
function Username() {
    const [visible, setVisible] = useState(false)
    const settingRef = useRef(null)
    useEffect(() => {
        function handleClickOutside(event) {
          if (settingRef.current && !settingRef.current.contains(event.target)) {
            setVisible(false)
          }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [settingRef]);
  return (
    <Container show={visible} ref={settingRef}>
        <UserInfo onClick={()=>setVisible(!visible)}>
            <Picture>
                <UserImage src={require('../../assets/dummy-user.jpg')} />
            </Picture>
            <PageWrapper>
                <Text>JhonMark</Text>
                <Text>Admin</Text>
            </PageWrapper>
        {
            visible ? 
            <UserSetting>
                <Text><MdSettings/> User Settings</Text>
                <Text><MdLogout/> Logout</Text>
            </UserSetting> : null
        }
        </UserInfo>
    </Container>
  )
}

export default Username