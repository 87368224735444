import React, { useState, useRef } from "react";
import styled from "styled-components";
import { MdClose } from "react-icons/md";
import { H2 } from "../UI/TextStyle";
import Spinner from "../Loading/Spinner";

const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
`;
const FormContainer = styled.div`
    ${props=>props.containerStyle}
    display: flex;
    min-width: 450px;
    min-height: fit-content;
    max-height: 90%;
    border-radius: 10px;
    background-color:white;
    position:relative;
    padding: 50px 40px 30px 40px;
    box-sizing: border-box;
    flex-direction: column;
    overflow: ${props => props.loading ? 'hidden' : 'auto'};
  > svg {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 25px;
    height: 25px;
    color: #7a7a7a;
    cursor: pointer;
  }
`;
const Title = styled(H2)`
  margin-bottom: 15px;
`;

const SpinnerWrapper = styled.div`
    top: ${props => `${props.position}px`};
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    position: absolute;
`

function ModalForm({
  children,
  title,
  onClose,
  showLoading,
  styleForm,
  responseMessages,
  responseStatus,
}) {
      const [scrollPosition, setScrollPosition] = useState(0)
      const refModal = useRef()
  return (
    <Container>
      <FormContainer 
        containerStyle={styleForm} 
        ref={refModal} 
        loading={showLoading ? 1 : 0}
        onScroll={() => {const { scrollTop } = refModal.current; setScrollPosition(scrollTop)}}>
        {showLoading &&
            <SpinnerWrapper position={scrollPosition}>
                <Spinner
                  messages={responseMessages}
                  responseStatus={responseStatus}
                />
            </SpinnerWrapper>
        }
        <Title>{title}</Title>
        {children}
        <MdClose onClick={onClose} />
      </FormContainer>
    </Container>
  );
}

export default ModalForm;
