import React, { useState } from 'react'
import { Label, Text } from './TextStyle'
import styled, { css } from 'styled-components'
import { colorTheme } from '../../constants/colors'
import SliderRadio from './SliderRadio'

const Container = styled.div`
  display:flex;
  width:100%;
  flex-direction:column;
  /* margin: 5px 0; */
  `
const defaultStyle = css`
  height:30px;
  width:100%;
  border:none;
  border-radius:5px;
  background-color:${colorTheme.secondary};
  padding:0px 10px;
  box-sizing:border-box;
  font-weight:400;
  font-size: 12px;
  font-family: Open Sans;
  &:disabled{
    /* border: solid 1px ${colorTheme.primary}; */
    /* color:${colorTheme.primary}; */
  }
  &:focus{
    outline:none;
  }`

const InputForm = styled.input`
  ${defaultStyle};
  border:${props => props.isError === '' || props.isError === undefined ? 'none' : `1px solid ${colorTheme.red}`};
  `

const InputFile = styled(InputForm)`
  display: flex;
  padding: 5px;
`

const InputPhone = styled(InputForm)`
  ::-webkit-inner-spin-button{
    -webkit-appearance: none; 
    margin: 0; 
  }
  ::-webkit-outer-spin-button{
    -webkit-appearance: none; 
    margin: 0; 
  }  
`
const RichForm = styled.textarea`
  ${defaultStyle};
  border:${props => props.isError === '' || props.isError === undefined ? 'none' : `1px solid ${colorTheme.red}`};
  height:100px;
  padding:10px;
  resize:none;
  `
const ErrorText = styled(Text)`
  text-align: flex-start;
  color:${colorTheme.red};
  height:18px;
  font-size:9pt;
  `
const DropdownForm = styled.select`
   ${defaultStyle};
   border:${props => props.isError === '' || props.isError === undefined ? 'none' : `1px solid ${colorTheme.red}`};
  `
const OptionsValues = styled.option``

const WrapperToggle = styled.div`
  display:flex;
  flex-direction:column;
  min-width: 150px;
  & Input {
    border: ${props => props.isError === '' || props.isError === undefined ? `1px solid ${colorTheme.primary}` : `1px solid ${colorTheme.red}`};
    color:${colorTheme.black};
  }
  & Input:disabled{
    border: solid 1px ${colorTheme.secondary};
    border: ${props => props.isError === '' || props.isError === undefined ? `1px solid ${colorTheme.secondary}` : `1px solid ${colorTheme.red}`};
    color:${colorTheme.gray};
  }
  `
const TitleToggle = styled.div`
  display:flex;
  align-items:center;
  justify-content:space-between;
  `
const WrapperInputCategories = styled.div`
  display:flex;
  & input{
    border-radius: 5px 0px 0px 5px;
  }
  & select{
    width: fit-content;
    background-color: ${colorTheme.primary};
    color: ${colorTheme.secondary};
    border-radius: 0px 5px 5px 0px;
  }

`
function InputToggle({ title, toggleValue, onChangeToggle, isError, ...config }) {
  return (
    <WrapperToggle isError={isError}>
      <TitleToggle>
        <Label>{title}</Label>
        <SliderRadio
          value={toggleValue}
          onClickChange={onChangeToggle}
        />
      </TitleToggle>
      <InputForm {...config} disabled={!toggleValue} isError={isError} /> 
    </WrapperToggle>
  )
}

function InputCategories({onChangedOpt}){
  const [valueOpt, setValueOpt] = useState('')
  const options = [
    { value: "pcs", title: "pcs" },
    { value: "lembar", title: "lembar" },
    { value: "meter", title: "meter" },
    { value: "yard", title: "yard" },
  ];
  return(
    <WrapperInputCategories>
      <InputForm type='number'/>
      <DropdownForm onChange={(e)=> {onChangedOpt(e.target.value); setValueOpt(e.target.value)}} value={valueOpt}>
        {
          options?.map((dt, index) => {
            return <OptionsValues key={index} value={dt.value}>{dt.title}</OptionsValues>
          })
        }
      </DropdownForm>
    </WrapperInputCategories>
  )
}


function Input({ inputStyle, title, errorText, options, isEditable, onChangeToggle, toggleValue, ...config }) {
  return (
    <Container>
      {
        inputStyle !== 'toggle' && <Label>{title}</Label>
      }
      {
        {
          'input': <InputForm isError={errorText} {...config} />,
          'textArea': <RichForm isError={errorText} spellCheck={false} {...config} />,
          'date': <InputForm isError={errorText} type='date' {...config} />,
          'phone': <InputPhone isError={errorText} {...config} />,
          'toggle' : <InputToggle title={title} toggleValue={toggleValue} isError={errorText} onChangeToggle={onChangeToggle} {...config} ></InputToggle>,
          'file' : <InputFile type='file' isError={errorText} {...config} />,
          'inputCategories' : <InputCategories/>,
          'select': <DropdownForm isError={errorText} {...config}>
            <OptionsValues value='' disabled>Select</OptionsValues>
            {
              options?.map((dt, index) => {
                return <OptionsValues key={index} value={dt.value}>{dt.title}</OptionsValues>
              })
            }
          </DropdownForm>
        }[inputStyle]
      }
      { 
        errorText !== undefined && <ErrorText>{errorText}</ErrorText>
      }
    </Container>
  )
}

export default Input