import styled from 'styled-components'
import React from 'react'
import { colorTheme } from '../../constants/colors'

const ButtonPrimary = styled.button`
  display:flex;
  justify-content:center;
  align-items:center;
  width:${props=>props.width};
  height:${props=>props.height};
  min-width:90px;
  min-height:30px;
  border-radius:5px;
  border:none;
  background-color:${colorTheme.primary};
  gap:0px 5px;
  color:#EDF2F4;
  padding:5px 10px;
  box-sizing: border-box;
  cursor:pointer;
  &:disabled{
    /* background-color: ${colorTheme.gray}; */
    opacity: .5;
  }
  `

const ButtonSecondary = styled(ButtonPrimary)`
  border:1px solid ${colorTheme.primary};
  background-color: transparent;
  border-color: ${props => props.disabled ? colorTheme.gray : colorTheme.primary};
  color: ${props => props.disabled ? colorTheme.gray : colorTheme.primary};
  opacity: ${props => props.disabled ? 0.5 : 1};
  `

const ButtonIcon = styled(ButtonPrimary)`
  width: 30px;
  min-width:30px;
  height: 30px;
  padding: 5px;
  box-sizing:border-box;
  > svg {
    color: ${colorTheme.white};
    width:16px;
    height:16px;
  }`

const ButtonIconSec = styled(ButtonIcon)`
  border:1px solid ${colorTheme.primary};
  background-color:transparent;
  color:${colorTheme.primary};
  > svg {
    color: ${colorTheme.primary};
  }`

const ButtonIconCircle = styled(ButtonPrimary)`
  width: 50px;
  height: 50px;
`
const ButtonSecondaryDanger = styled(ButtonSecondary)`
  border-color: ${colorTheme.red};
  color: ${colorTheme.red};
`
const ButtonDanger = styled(ButtonPrimary)`
  background-color: ${colorTheme.red};
`

function Button({btnStyle, children, width, height, ...config}) {
  function typeHandler(){
    switch(btnStyle){
      case 'secondary': return <ButtonSecondary width={width} height={height} {...config}>{children}</ButtonSecondary>;
      case 'secondaryDanger': return <ButtonSecondaryDanger width={width} height={height} {...config}>{children}</ButtonSecondaryDanger>;
      case 'danger': return <ButtonDanger width={width} height={height} {...config}>{children}</ButtonDanger>;
      case 'icon': return <ButtonIcon {...config}>{children}</ButtonIcon>
      case 'iconSecondary': return <ButtonIconSec {...config}>{children}</ButtonIconSec>
      default: return <ButtonPrimary width={width} height={height} {...config}>{children}</ButtonPrimary>;
    }
  }
  return (
    <>
      {typeHandler()}
    </>
  )
}

export default Button
