import axios from "axios"
// import { newOrder } from "../backend/order"

const defaultUrl = 'https://www.oshwinwashing.com/api'

const header = {
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type":"application/json"
    }
}

export async function getDataAPI(url) {
    const response = await axios.get(defaultUrl + url)
    return response
}

export async function postDataAPI(url, value) {
    const response = await axios.post(defaultUrl + url, value, header)
    return response
}

export async function updateDataAPI(url, value) {
    const response = await axios.put(defaultUrl + url, value, header)
    return response
}

export async function deleteDataAPI(url, id) {
    const response = await axios.delete(defaultUrl + url + id, header)
    return response
}

export async function patchDataAPI(url, id, status){
    const response = await axios.patch(defaultUrl + url + id + '/' + status, header)
    return response
}

export async function filterDataAPI(url, searchInput){
    const response = await axios.get(`https://oshwinwashing.com/api/${url}?filter=${searchInput}`)
    return response
}
