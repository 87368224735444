import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BoxWrapper from "../../components/BoxWrapper/BoxWrapper";
import Button from "../../components/UI/Button";
import { H1, Text, TitleSection } from "../../components/UI/TextStyle";
import { colorTheme } from "../../constants/colors";
import {
  Pie,
  PieChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Label,
  ComposedChart,
  ResponsiveContainer,
} from "recharts";
import { useLocation, useNavigate } from "react-router-dom";
import TableStyled from "../../components/UI/TableStyled";
import GHSTooltips from "../../components/GHSTooltips/GHSTooltips";
import { ghs } from "../../data/ghs";

const Container = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  min-height: 500px;
  overflow-y: scroll;
  overflow-x: unset;
  grid-template-areas:
    "desc storage usage button"
    "table table table table "
    "footer footer footer footer";
  grid-template-columns: 300px 200px auto 150px;
  grid-template-rows: 220px minmax(350px, auto) 40px;
  gap: 20px;
`;

const Desc = styled.div`
  grid-area: desc;
  display: flex;
  flex-direction: column;
  height: 100%;
  /* justify-content: space-between; */
  & H1 {
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  & ${Text} {
    margin-top:10px;
    font-size: 12px;
  }
  & span {
    font-weight: 700;
  }
`;
const Storage = styled(BoxWrapper)`
  grid-area: storage;
  overflow: hidden;
  position:relative;
  & ${Text}{
    position: absolute;
    bottom:20%;
    left:0;
    right: 0;
    text-align: center;
  }
`;
const Usage = styled(BoxWrapper)`
  grid-area: usage;
  overflow: unset;
`;
const ButtonWrapper = styled.div`
  grid-area: button;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  & Button {
    height: 35px;
    width: 100%;
  }
  & Button:nth-child(1n + 2) {
    background-color: ${colorTheme.white};
    color: ${colorTheme.primary};
  }
`;

const TableHistory = styled(BoxWrapper)`
  grid-area: table;
`;

const GHS = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`

function StorageDetails() {
  const datas = [
    {
      name: "Jan",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Feb",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Mar",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "Apr",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "May",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Jun",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "Jul",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
    {
      name: "Aug",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
    {
      name: "Sep",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
    {
      name: "Oct",
      uv: 349,
      pv: 430,
      amt: 2100,
    },
    {
      name: "Nov",
      uv: 3490,
      pv: 0,
      amt: 2100,
    },
    {
      name: "Dec",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];
  const data = [
    { name: 'amount', value: 0 },
    { name: 'capacity', value: 0}
  ];
  const columnHistory = [
    { heading: "Date Process", value: "date" },
    { heading: "Process", value: "process" },
    { heading: "Operator", value: "operator" },
    { heading: "Order Number", value: "number" },
    { heading: "Article Name", value: "article_name" },
    { heading: "Type", value: "type" },
    { heading: "Amount", value: "amount" },
    { heading: "Remaining", value: "remaining" },
    { heading: "Create by", value: "create_by" },
  ];
  const navigate = useNavigate()
  const location = useLocation()
  const [chartCapacity, setChartCapacity] = useState(data)
  const [ghsStatement, setGhsStatement] = useState([])
  useEffect(()=>{
    function datasHandler(){
      const chartsData = [...data]
      chartsData[0].value = location.state.stock
      chartsData[1].value = location.state.capacity - location.state.stock < 0 ? 0 : location.state.capacity - location.state.stock
      //ghs state
      console.log(location.state.ghs)
      const ghsArr = location.state.ghs.length > 1 ? location.state.ghs.split(',') : [...location.state.ghs]
      setGhsStatement(ghsArr)
    }
    datasHandler()
  },[])
  return (
    <Container>
      <Desc>
        <H1>{location.state.name}</H1>
        <GHS>
          {
            ghsStatement.map(key=>{
              const data = ghs.find(dt => dt.id === key )
              // console.log(data)
              return(
                <GHSTooltips 
                    key={data.id}
                    src={data.icon}
                    title={data.name}
                    desc={data.desc}
                  />
              )}) 
          }
        </GHS>
        <Text>
          <span>Desc:</span> {location.state.desc !== undefined ? location.state.desc : '-'}
        </Text>
      </Desc>
      <Storage>
        <TitleSection>Capacity</TitleSection>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart width='100%' height='100%' >
            <Pie
              data={chartCapacity}
              cy='75%'
              cx='50%'
              startAngle={180}
              endAngle={0}
              innerRadius={55}
              outerRadius={75}
              fill='#fff'
              paddingAngle={0}
              dataKey="value"
            >
              <Cell fill={colorTheme.primary} />
              <Cell fill={colorTheme.secondary} />
            </Pie>
          </PieChart>
        </ResponsiveContainer>
        <Text>{location.state.stock+'/'+location.state.capacity}<br/>{location.state.units}</Text>
      </Storage>
      <Usage>
        <TitleSection>Usage</TitleSection>
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            width="100%"
            height="100%"
            data={datas}
            margin={{
              top: 15,
              right: 20,
              bottom: 0,
              left: -10,
            }}
          >
            <XAxis dataKey="name" />
            <YAxis dataKey="pv" tickLine={false} axisLine={false}>
              <Label
                value="Total Production (pcs)"
                offset={0}
                angle={-90}
                position="insideLeft"
                style={{ textAnchor: "middle" }}
              />
            </YAxis>
            <Cell />
            <Tooltip />
            <Bar dataKey="pv" fill={colorTheme.primary} stackId="a" />
            {/* <Line type="monotone" dataKey="pv" stroke="#ff7300" /> */}
            <CartesianGrid vertical={false} opacity={0.5} stroke="#DFE2E6" />
          </ComposedChart>
        </ResponsiveContainer>
      </Usage>
      <ButtonWrapper>
        <Button>Restock</Button>
        <Button>Stock out</Button>
        <Button>Edit</Button>
      </ButtonWrapper>
      <TableHistory>
        <TableStyled column={columnHistory} data={[]} />
      </TableHistory>
      <Button onClick={()=> navigate(-1)}>Close</Button>
    </Container>
  );
}

export default StorageDetails;
