import React, { useEffect, useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Button from "../../components/UI/Button";
import { H4, Text } from "../../components/UI/TextStyle";
import { colorTheme } from "../../constants/colors";
import { MdClose } from "react-icons/md";
import BoxWrapper from "../../components/BoxWrapper/BoxWrapper";
import Table from "../../components/UI/Table";
import { getDataAPI } from "../../utility/http";
import FormCustomer from "./FormCustomer";
import OrderDetails from "../Order/OrderDetails";
import Modal from "../../components/Modal/Modal"; 

const Container = styled(BoxWrapper)`
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  & > svg {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
  & Button {
    margin-bottom: 30px;
  }
`;

const CustomerInfo = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const CustPicture = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin-right: 50px;
  > svg {
    width: 120px;
    height: 120px;
    margin-bottom: 20px;
    color: ${colorTheme.gray};
  }
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1000px;
  flex-wrap: wrap;
  gap: 30px 50px;
`;

const Info = styled.div`
  min-width: 150px;
  max-width: 250px;
  width: 100%;
  & ${Text}:last-child {
    font-weight: 600;
  }
`;

function CustomerDetails() {
  const [custInfo, setCustInfo] = useState(null)
  const [tableOrder, setTableOrder] = useState([]);
  const [showEditCustomer, setShowEditCustomer] = useState(false);
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const column = [
    { heading: "Order Number", value: "number" },
    { heading: "Date Order", value: "date" },
    { heading: "Type Order", value: "type" },
    { heading: "Total Article", value: "article_count" },
    { heading: "Process", value: "process" },
    { heading: "Status", value: "status_name" },
  ];
  
  const location = useLocation();
  const navigate = useNavigate();
  const userId = location.pathname.split('/')[2]
  // async function getData() {
  //   try {
  //     const responseCustomer = await getDataAPI(`customer/${userId}`)
  //     const responseOrder = await getDataAPI(`order/customer/${userId}`)
  //     setCustInfo(responseCustomer.data.Data)
  //     responseOrder.data.Data !== null && setTableOrder(responseOrder.data.Data)
  //   } catch (err){
  //     console.log(err)
  //   }
  // }
  
  async function getData(){
    try {
      const responseCustomer = await getDataAPI(`/customer/${userId}`)
      const responseOrder = await getDataAPI(`/order?customer_id=${userId}`)
      
      setCustInfo(responseCustomer.data.Data)
      responseOrder.data.Data !== null && setTableOrder(responseOrder.data.Data)
      responseOrder.data.Data.forEach(dt => {
        // console.log(dt)
      })
    } catch (err){
      console.log(err)
    }
  }

  useEffect(() => {
    async function getDataHandler(){
      try {
        const responseCustomer = await getDataAPI(`/customer/${userId}`)
        const responseOrder = await getDataAPI(`/order?customer_id=${userId}`)
        setCustInfo(responseCustomer.data.Data)
        responseOrder.data.Data !== null && setTableOrder(responseOrder.data.Data)
      } catch (err){
        console.log(err)
      }
    }
    getDataHandler()
  }, [userId]);

  async function updateCustomerHandler(){
    setShowEditCustomer(false)
    await getData()
  }

  function showTableHandler(){
    if(tableOrder.length > 0){
      const tableValues = tableOrder?.map(dt => {
        let date = new Date(dt.date)
        return ({
          ...dt,
          date: date.toLocaleDateString('en-GB')
        })
      })
      return tableValues
    }else{
      return []
    }
  }

  function orderDetailsHandler(dt){
    setShowOrderDetails(true)
    setSelectedOrder(dt)
    // console.log(dt)
    navigate(`/order/number/${dt.number}`)
  }

  return (
    <>
      <Container>
        <MdClose onClick={() => navigate(-1)} />
        <H4>Customer Information</H4>
        <CustomerInfo>
          <CustPicture>
            <FaUserCircle />
            <Button
              btnStyle="secondary"
              onClick={() => setShowEditCustomer(true)}
            >
              Edit Profile
            </Button>
          </CustPicture>
          <Wrapper>
            <Info>
              <Text>Name</Text>
              <Text>{custInfo?.name !== '' ? custInfo?.name : '-'}</Text>
            </Info>
            <Info>
              <Text>Phone</Text>
              <Text>{custInfo?.phone !== '' ? custInfo?.phone : '-'}</Text>
            </Info>
            <Info>
              <Text>Phone 2</Text>
              <Text>{custInfo?.other_phone !== '' ? custInfo?.other_phone : '-'}</Text>
            </Info>
            <Info>
              <Text>Email</Text>
              <Text>{custInfo?.email !== '' ? custInfo?.email : '-'}</Text>
            </Info>
            <Info>
              <Text>Address</Text>
              <Text>{custInfo?.address_line !== '' ? custInfo?.address_line : '-'}</Text>
            </Info>
          </Wrapper>
        </CustomerInfo>
        <H4>Order History</H4>
        <Table 
          column={column} 
          data={showTableHandler(tableOrder)}
          onClickRow={(dt)=> orderDetailsHandler(dt)}
        />
      </Container>
      {/* {
        showOrderDetails &&
        <Modal>
          <OrderDetails
            data={selectedOrder}
          />
        </Modal>
      } */}
      {
        showEditCustomer &&
        <FormCustomer
          onClose={()=> setShowEditCustomer(false)}
          data={custInfo}
          onSuccess={updateCustomerHandler}
        />
      }
      
    </>
  );
}

export default CustomerDetails;
