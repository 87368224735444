import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import styled from "styled-components";
import { colorTheme } from "../../constants/colors";

const Container = styled.div`
  display:flex;
  flex-direction:column;
  width:100%;
  `
const TabLink = styled(NavLink)`
    display:flex;
    height:30px;
    margin: 0 15px;
    justify-content:center;
    align-items:center;
    &.active{
        color:${colorTheme.primary};
        border-bottom: 2px solid ${colorTheme.primary};
    }
`

const TabMenu = styled.div`
    display:flex;
    margin-bottom: 20px;
    > a {
        font-size:13px;
        font-weight:500;
        text-decoration:none;
        color:${colorTheme.gray};
    }
    & ${TabLink}:first-child {
        border-radius: 10px 0 0 0;
        margin-left: 0px;
    }
    & ${TabLink}:last-child {
        border-radius: 0 10px 0 0;
        margin-right: 0px;
    }
`
function ProductAndService() {
  return (
    <Container>
      <TabMenu>
        <TabLink to="/product/production">Production</TabLink>
        <TabLink to="/product/manage-product">Manage Services</TabLink>
      </TabMenu>
      <Outlet />
    </Container>
  );
}

export default ProductAndService;
