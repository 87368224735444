import React, { useEffect, useState } from "react";
import { FaPen, FaPlus, FaTrash} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import BoxWrapper from "../../components/BoxWrapper/BoxWrapper";
import Confirmation from "../../components/Modal/Confirmation";
import Button from "../../components/UI/Button";
import InputClear from "../../components/UI/Input/InputClear";
import TableStyled from "../../components/UI/TableStyled";
import { H3 } from "../../components/UI/TextStyle";
import { colorTheme } from "../../constants/colors";
import { deleteCustomer, deleteDataAPI, filterDataAPI, getCustomer, getDataAPI } from "../../utility/http";
import FormCustomer from "./FormCustomer";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  & tr{
    cursor: pointer;
  }
  & th:nth-child(2) {
    width: 20%;
  }
  & th:nth-child(6) {
    width: 30%;
  }
`;
const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
`;
const TR = styled.tr`
  width: 100%;
`;
const TD = styled.td`
  font-size: 12px;
  padding: 8px;
  > svg {
    width: 15px;
    margin-right: 5px;
    height: 15px;
    cursor: pointer;
    color: ${colorTheme.gray};
  }
`;
const Content = styled(BoxWrapper)``;
function Customer() {
  const [showFormCustomer, setShowFormCustomer] = useState(false);
  const [dataInAction, setDataInAction] = useState(null);
  const [inputSearchValue, setInputSearchValue] = useState("");
  const [loadingTable, setLoadingTable] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [responseMessageModal, setResponseMessageModal] = useState("");
  const [responseStatusModal, setResponseStatusModal] = useState(null);
  const navigate = useNavigate()
  
  const column = [
    { heading: "No", value: "no" },
    { heading: "Customer Name", value: "name" },
    { heading: "Phone", value: "phone" },
    { heading: "Phone 2", value: "other_phone" },
    { heading: "Email", value: "email" },
    { heading: "Address", value: "address_line" },
  ];
  async function getCustomerData() {
    setLoadingTable(true);
    if (inputSearchValue.length > 0) {
      await filterDataAPI('customer', inputSearchValue)
      .then((res) => {
            console.log(res)
          setCustomerList(res.data.Data);
        })
        .catch((err) => {
          console.log(err);
        });
        setLoadingTable(false);
    } else {
      await getDataAPI('/customer?status=available')
        .then((res) => {
          setCustomerList(res.data.Data);
          setTimeout(() => {
            setLoadingTable(false);
          }, 150);
        })
        .catch((e) => {
          setTimeout(() => {
            setLoadingTable(false);
          }, 150);
          console.log(e);
        });
    }
  }
  useEffect(() => {
    getCustomerData();
  }, []);

  useEffect(() => {
    async function getFilterData() {
      const value = inputSearchValue;
      if (value.length > 0) {
        await getCustomerData("filter");
      } else {
        await getCustomerData();
      }
    }
    getFilterData();
  }, [inputSearchValue]);

  function onEditHandler(values) {
    setDataInAction(values);
    setShowFormCustomer(true);
  }
  function onDeleteHandler(values) {
    setShowConfirmModal((prevState) => ({
      ...prevState,
      value: true,
      type: "delete",
    }));
    setDataInAction(values);
  }
  async function confirmAction() {
    setResponseStatusModal(null);
    await deleteDataAPI('/customer/', dataInAction.id)
      .then((res) => {
        setResponseMessageModal(res.data.Messages);
        setTimeout(async () => {
          res.status === 200 && getCustomerData();
          setResponseStatusModal(true);
          setTimeout(() => {
            setResponseMessageModal("");
            setShowConfirmModal(false);
            setDataInAction(null);
          }, 1500);
        }, 1500);
      })
      .catch((error) => {
        setResponseStatusModal(false);
        console.log(error);
      });
  }
  return (
    <>
      <Container>
        <FilterWrapper>
          <InputClear
            inputType="input"
            placeholder="Search Name of product"
            onClickClose={() => setInputSearchValue("")}
            onChange={(e) => setInputSearchValue(e.target.value)}
          />
          <Button onClick={() => setShowFormCustomer(true)}>
            <FaPlus />
            Add Customer
          </Button>
        </FilterWrapper>
        <Content>
          <H3>Table Items</H3>
          <TableStyled
            column={column}
            actions={["delete", "edit"]}
            loading={loadingTable}
          >
            {customerList?.map((dt, idx) => (
              <TR key={idx} >
                <TD onClick={()=> navigate(`${dt.id}`,{state: dt.id})}>{idx + 1}</TD>
                {column.map((item, index) => {
                  if (item.value !== "no") {
                    return <TD key={index} onClick={()=> navigate(`${dt.id}`,{state: dt.id})}>{dt[item.value]}</TD>;
                  } else {
                    return null;
                  }
                })}
                <TD>
                  <FaPen onClick={() => onEditHandler({ ...dt })} />
                  <FaTrash onClick={() => onDeleteHandler(dt)} />
                </TD>
              </TR>
            ))}
          </TableStyled>
        </Content>
      </Container>
      {showFormCustomer && (
        <FormCustomer
          onClose={() => {
            setShowFormCustomer(false);
            setDataInAction(null);
          }}
          onSuccess={getCustomerData}
          data={dataInAction}
        ></FormCustomer>
      )}
      {showConfirmModal && (
        <Confirmation
          title="Delete this customer ?"
          desc="By confirming this action will permanently remove it from database, you cannot undo this change"
          messages={responseMessageModal}
          responseStatus={responseStatusModal}
          onCancel={() => setShowConfirmModal(false)}
          onConfirm={confirmAction}
        />
      )}
    </>
  );
}

export default Customer;
