import styled from "styled-components"
import { colorTheme } from "../../constants/colors"

export const H1 = styled.h1`
  font-weight:600;
  font-size: 16pt;
  margin: 0px;
  padding: 0px;
`
export const H2 = styled.h2`
  font-weight:600;
  font-size: 14pt;
  margin: 0px;
  padding: 0px;
`
export const H3 = styled.h3`
  font-weight:600;
  font-size: 15px;
  margin: 0px;
  padding: 0px;
`
export const H4 = styled.h4`
  font-weight:600;
  font-size: 12px;
  margin: 0px;
  padding: 0px;
`

export const Text =styled.p`
  font-weight:400;
  font-size: 10pt;
  margin: 0px;
  padding: 0px;
  text-align:left;
  `
export const Label = styled.label`
  font-weight:400;
  font-size: 12px;
  margin: 0px;
  padding: 0px;
  text-align:left;
`
export const SectionTitle = styled(H4)`
  margin: 5px 0px 10px 0px;
`
export const TitleSection = styled(H3)`
  color: ${colorTheme.primary};
`