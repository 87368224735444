import styled from "styled-components";
import React, { useEffect, useRef, useState } from 'react'
import { FiSearch } from "react-icons/fi";
import { MdClose } from "react-icons/md";
import { Label, Text } from "./TextStyle";
import { colorTheme } from "../../constants/colors";
import Ellipsis from "../Loading/Ellipsis";

const Wrapper = styled.div`
    display: flex;
    width:100%;
    justify-content: center;
    align-items: center;
    border-radius:7px;
    padding: 0 5px;
    box-sizing:border-box;
    border:1px solid;
    border-color:${props=> props.isError !== '' && props.isError !== undefined ? colorTheme.red :  props.isDisable ? colorTheme.primary : colorTheme.secondary};
    background-color: ${colorTheme.white};
    position:relative;
    > svg{
        width: 18px;
        height: 100%;
        cursor: pointer;
        color:${props=> props.isDisable ? colorTheme.primary : colorTheme.gray};
    }
`
const Input = styled.input`
    width: 100%;
    height: 30px;
    border: none;
    font-weight:400;
    font-size: 10pt;
    font-family: Open Sans;
    background-color:${colorTheme.white};
    &:focus{
        outline:none;
    } 
    &:disabled{
        color:${colorTheme.primary};
    }
    
`
const Result = styled.div`
    display:flex;
    flex-direction: column;
    width: 100%;
    max-height:100px;
    border-radius:0px 0px 10px 10px;
    background-color:#EDF2F4;
    position: absolute;
    top: 100%;
    box-sizing:border-box;
    overflow:auto;
    box-shadow:0px 2px 5px rgba(0,0,0,0.3);
`
const TextResult = styled(Text)`
    background-color:transparent;
    padding: 5px 15px;
    cursor:pointer;
    &:hover{
        background-color:${colorTheme.primary};
    }
`
const TextCreate = styled(TextResult)`
    color: ${colorTheme.primary};
    &:hover{
        background-color:${colorTheme.primary};
        color:${colorTheme.secondary};
    }
`
const TextNoData = styled(Text)`
    background-color:transparent;
    padding: 5px 15px;
`

const ErrorText = styled(Text)`
    color:${colorTheme.red};
`
const SearchBar = (props, ref) => {
    const [inputDisable, setInputDisable] = useState(false)
    const [showResult, setShowResult] = useState(null)
    const [dataList, setDataList] = useState([])
    const [inputValue, setInputValue] = useState('')
    const catMenu = useRef(null)
    useEffect(()=>{
        function dataListHandler(){
            if(props.data === undefined || props.data === null){
                setDataList([])
            }else{
                setDataList(props.data)
            }
        }
        dataListHandler()
    },[props.data])
    useEffect(()=>{
        function changeInputHandler(){
            props.onChangeInput(inputValue)
        }
        changeInputHandler()
    },[inputValue])

    useEffect(() => {
        const closeOpenMenus = (e)=>{
            if(catMenu.current && !catMenu.current.contains(e.target)){
              setShowResult(false)
            }
        }
      document.addEventListener('mousedown',closeOpenMenus)
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", closeOpenMenus);
      };
    }, [ref]);

    function closeHandler() {
        props.onClickClose()
        setInputValue('')
        setInputDisable(false)
    }

    function onClickSelected(dt){
        if(dt === ''){
            props.onClickCreate()
        }else{
            setInputValue(dt.name)
            props.selectedValue(dt)
            setInputDisable(true)
        }
        setShowResult(false)
    }
    function onChangeHandler(e){
        setShowResult(true)
        setInputValue(e.target.value)
    }

  return (
    <>
        <Wrapper isDisable={inputDisable} isError={props.errorText} ref={catMenu}>
            <Input onChange={onChangeHandler} onFocus={()=>setShowResult(true)} placeholder={props.placeholder} value={inputValue} disabled={inputDisable} ref={catMenu}/>
            {inputValue === '' ? <FiSearch/> : <MdClose onClick={closeHandler}/>}
            { props.dropDown && <Result ref={catMenu}>
                { showResult && props.createButton !== undefined && props.createButton === true && <TextCreate onClick={()=>{onClickSelected('')}} >+ Create New</TextCreate>}
                { props.loading ? <Ellipsis/> :
                    showResult && dataList.slice(0,30).map(dt=>{
                    return <TextResult key={dt.id} onClick={()=>onClickSelected(dt)} onBlur={()=> console.log('out')}>{dt.name}</TextResult>
                })
                }
                { showResult && dataList.length < 1 && <TextNoData>No Data</TextNoData>}
            </Result>
            }
        </Wrapper>
        <ErrorText>{props.errorText}</ErrorText> 
    </>
  )
}

export default SearchBar