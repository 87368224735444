import React, { useEffect, useRef, useState } from 'react'
import { FaBell } from 'react-icons/fa'
import styled from 'styled-components'
import { colorTheme } from '../../constants/colors'
import { H4, Text } from '../UI/TextStyle'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0 20px;
`
const NumberNotif = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: -3px;
    top: 0;
    background-color: ${colorTheme.red};
    height: 18px;
    width: 18px;
    padding:3px;
    border-radius: 50%;
    & ${Text}{
        font-size: 12px;
        color: ${colorTheme.white};
        user-select: none;
    }
`
const IconNotif = styled.div`
    display: flex;
    position: relative;
    cursor: pointer;
    > svg{
        height: 20px;
        width: 20px;
        padding: 10px;
        border-radius: 50%;
        color:${colorTheme.gray};
        background-color: ${colorTheme.white};
    };
`

const NotifList = styled.div`
    display: flex;
    flex-direction: column;
    top:50px;
    left: -90px;
    position: absolute;
    max-height: 500px;
    width: 300px;
    background-color: ${colorTheme.white};
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
    z-index: 2;
    box-shadow: 3px 3px 16px rgba(0,0,0,0.2);
    > H4 {
        margin-bottom: 10px;
        user-select: none;
    }
    & ${Text} {
        /* color: red; */
        font-weight: 300;
    }
`

const NotifDetails = styled.div`
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgba(0,0,0,0.2);
    padding: 10px 0;
    & ${H4}{
        font-size: 13px;
    }
`
function Notification() {
    const [showList, setShowList] = useState(false)
    const settingRef = useRef(null) 
    useEffect(() => {
        function handleClickOutside(event) {
          if (settingRef.current && !settingRef.current.contains(event.target)) {
            setShowList(false)
          }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [settingRef]);
    return (
        <Wrapper ref={settingRef}>
            <IconNotif onClick={() => setShowList(!showList)}>
                <FaBell />
                <NumberNotif>
                    <Text>99+</Text>
                </NumberNotif>
            </IconNotif>
            {
                showList &&
                <NotifList>
                    <H4>Notification</H4>
                    <NotifDetails>
                        <H4>Customer Update</H4>
                        <Text>There is an update in customer ajsbd djahsjash dhasd iasd</Text>
                    </NotifDetails>
                    <NotifDetails>
                        <H4>Customer Update</H4>
                        <Text>There is an update in customer ajsbd djahsjash dhasd iasd</Text>
                    </NotifDetails>
                    <NotifDetails>
                        <H4>Customer Update</H4>
                        <Text>There is an update in customer ajsbd djahsjash dhasd iasd</Text>
                    </NotifDetails>
                </NotifList>
            }
        </Wrapper>
    )
}

export default Notification