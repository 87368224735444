import React from "react";
import styled, { keyframes } from "styled-components";
import { colorTheme } from "../../constants/colors";

const ellipsisAnimation1 = keyframes`
 0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;
const ellipsisAnimation3 = keyframes`
 0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;
const ellipsisAnimation2 = keyframes`
0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
`;

const Wrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
  transform: scale(40%);
  & div {
    position: absolute;
    /* top: 33px; */
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: ${colorTheme.primary};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  & div:nth-child(1) {
    left: 8px;
    animation: ${ellipsisAnimation1} 0.6s infinite;
  }
  & div:nth-child(2) {
    left: 8px;
    animation: ${ellipsisAnimation2} 0.6s infinite;
  }
  & div:nth-child(3) {
    left: 32px;
    animation: ${ellipsisAnimation2} 0.6s infinite;
  }
  & div:nth-child(4) {
    left: 56px;
    animation: ${ellipsisAnimation3} 0.6s infinite;
  }
`;

function Ellipsis() {
  return (
    <Wrapper>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </Wrapper>
  );
}

export default Ellipsis;
