import React from 'react'
import { Route, Routes, Navigate} from 'react-router-dom';
import GlobalStyle from './constants/globalStyles';
import ProductAndService from './containers/ProductAndService/ProductAndService';
import ManageProduct from './containers/ProductAndService/ManageProduct';
import Layout from './layout/Layout';
import Production from './containers/ProductAndService/Production';
import Order from './containers/Order/Order';
import Customer from './containers/Customer/Customer';
import CustomerDetails from './containers/Customer/CustomerDetails';
import FormOrder from './containers/Order/FormOrder';
import OrderDetails from './containers/Order/OrderDetails';
import Storage from './containers/Storage/Storage';
import RawStorage from './containers/Storage/RawStorage';
import SubstanceStorage from './containers/Storage/SubstanceStorage';
import StorageDetails from './containers/Storage/StorageDetails';
import ErrorPage from './containers/ErrorPage/ErrorPage';

function App() {
  
  return (
    <>
      <GlobalStyle />
      <Routes element={<Layout />}>
        {/* <Route exact path='login' element={<Login />} /> */}
        <Route exact element={<Layout />}>
          <Route exact path='/' element={<ProductAndService />} />
          <Route path='product' element={<ProductAndService />}>
            <Route path='production' element={<Production />} />
            <Route path='manage-product' element={<ManageProduct/>} />
          </Route>
          <Route path='customer' element={<Customer/>}/>
          <Route path='customer/:userid' element={<CustomerDetails/>}/>
          <Route path='order' element={<Order />} />
          <Route path='order/number/:orderId' element={<OrderDetails />} />
          <Route path='order/number/:orderId/edit' element={<FormOrder />} />
          <Route path='order/new' element={<FormOrder />} />
          <Route path="storage" element={<Navigate to="/storage/raw" replace />}/>
          <Route path='storage' element={<Storage />}>
            <Route path='raw' element={<RawStorage />} />
            <Route path='raw/details' element={<StorageDetails />} />
            <Route path='substance' element={<SubstanceStorage />} />
            <Route path='substance/details' element={<StorageDetails />} />
          </Route>
          <Route exact path='/sales' element={<ProductAndService />} />
          <Route exact path='/employee' element={<ProductAndService />} />
          <Route exact path='/settings' element={<ProductAndService />} />
          <Route exact path='/product' element={<ProductAndService />} />
          <Route exact path='/shipping' element={<ProductAndService />} />
          <Route exact path='/customer' element={<ProductAndService />} />
        </Route>
        <Route exact path='*' element={<ErrorPage />} />
      </Routes>
    </>
  );
}

export default App;
