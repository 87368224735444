import React, { useState } from 'react'
import styled from 'styled-components'
import { colorTheme } from '../../constants/colors'
import { H3, Text } from '../UI/TextStyle'

const Container = styled.div`
    display: flex;
    position: relative;
`
const InfoWrapper = styled.div`
    display: flex;
    width:250px;
    height: 100px;
    background-color: white;
    border: 1px solid ${colorTheme.red};
    border-radius: 5px;
    z-index: 5;
    padding: 10px;
    box-sizing: border-box;
    /* justify-content: center; */
    align-items: center;
    position: absolute;
    top: 100%;
    left: 50%;
    gap: 10px;
    visibility: ${props => props.show ? 'visible' : 'hidden'};
    transition: all .1s ease-in-out;
    transform: scale(${props => props.show ? 1 : 0.7});
    & img{
        width: 70px;
        height: 70px;
    }
`
const IconGHS = styled.img`
  width: 35px;
  height: 35px;
  cursor: pointer;`
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`

function GHSTooltips({src, title, desc, ...rest}) {
    const [show, setShow] = useState(false)
  return (
    <Container>
        <IconGHS src={src} 
            {...rest}
            onMouseEnter={() => setShow(true)}
            onMouseLeave={() => setShow(false)}/>
        <InfoWrapper show={show}>
            <IconGHS src={src} />
            <Wrapper>
            <H3>{title}</H3>
            <Text>{desc}</Text>
            </Wrapper>
        </InfoWrapper>
    </Container>
  )
}

export default GHSTooltips