import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ModalForm from "../../components/ModalForm/ModalForm";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import { H4 } from "../../components/UI/TextStyle";
import { colorTheme } from "../../constants/colors";
import { postDataAPI, updateDataAPI } from "../../utility/http";
import { formatStringNumber } from "../../utility/inputFormatter";
import { checkValidity } from "../../utility/inputValidation";

const Container = styled.div`
  width: 500px;
  /* background-color: red; */
  & H4{
    margin:10px 0;
  }
`;
const Wrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: ${(props) => props.width};
  gap: 0px 40px;
  justify-content: space-between;
  > label {
    color: ${colorTheme.primary};
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 10px;
  column-gap: 15px;
`;
function FormCustomer({ onClose, onSuccess, data }) {
  const initialStateCustomer = {
    name: {
      value: "",
      status: false,
      errorText: "",
      validation: { required: true },
    },
    phone: {
      value: "",
      status: false,
      errorText: "",
      validation: { required: true },
    },
    other_phone: {
      value: "",
      status: false,
      errorText: "",
      validation: { required: false },
    },
    email: {
      value: "",
      status: false,
      errorText: "",
      validation: { isEmail: true },
    },
    line: {
      value: "",
      status: false,
      errorText: "",
      validation: { required: true },
    },
    province_id: {
      value: "",
      status: false,
      errorText: "",
      validation: { required: true },
    },
    city_id: {
      value: "",
      status: false,
      errorText: "",
      validation: { required: true },
    },
    district_id: {
      value: "",
      status: false,
      errorText: "",
      validation: { required: true },
    },
    sub_district_id: {
      value: "",
      status: false,
      errorText: "",
      validation: { required: true },
    },
    postal_code: {
      value: "",
      status: false,
      errorText: "",
      validation: { required: false },
    },
  };
  const [customerValues, setCustomerValues] = useState(initialStateCustomer);
  const [provinces, setProvinces] = useState([]);
  const [city, setCity] = useState([]);
  const [district, setDistrict] = useState([]);
  const [subDistrict, setSubDistrict] = useState([]);
  const [loading, setLoading] = useState(false)
  const [statusResponse, setStatusResponse] = useState(null);
  const [responseMessage, setResponseMessage] = useState("");
  const [formEditMode, setFormEditMode] = useState(false)
  useEffect(() => {
    async function getProvince() {
      const resProvinces = await axios.get(
        "https://oshwinwashing.com/api/province"
      );
      try {
        setProvinces(optionsHandler(resProvinces.data.Data));
      } catch (e) {
        console.log(e);
      }
    }
    getProvince();
  }, []);
  useEffect(() => {
    async function getData() {
      if (data !== null && data !== undefined) {
        setFormEditMode(true);
        const keyValues = ['name', 'phone', 'other_phone', 'email', 'address']
        keyValues.forEach((key) => {
          let value = data[key];
          if(key === 'address'){
            const {id, country_id, customer_id, ...restData} = data.address
            Object.keys(restData).forEach((addressKey)=> {
              setCustomerValues((prevState) => ({
                ...prevState,
                [addressKey]: {
                  ...prevState[addressKey],
                  value: restData[addressKey],
                },
              }));
            })
          }
          else{
            setCustomerValues((prevState) => ({
              ...prevState,
              [key]: {
                ...prevState[key],
                value: value,
              },
            }));
          }
        });
      }
    }
    getData();
  }, [data]);
  useEffect(() => {
    async function getCity() {
      const idProvince = customerValues.province_id.value;
      const resCity = await axios.get(
        `https://oshwinwashing.com/api/city/province/${idProvince}`
      );
      try {
        setCity(optionsHandler(resCity.data.Data));
      } catch (e) {
        console.log(e);
      }
    }
    customerValues.province_id.value !== "" && getCity();
  }, [customerValues.province_id]);

  useEffect(() => {
    async function getDistrict() {
      const idCity = customerValues.city_id.value;
      const resDistrict = await axios.get(
        `https://oshwinwashing.com/api/district/city/${idCity}`
      );
      try {
        setDistrict(optionsHandler(resDistrict.data.Data));
      } catch (e) {
        console.log(e);
      }
    }
    customerValues.city_id.value !== "" && getDistrict();
  }, [customerValues.city_id]);

  useEffect(() => {
    async function getsubDistrict() {
      const idDistrict = customerValues.district_id.value;
      const ressubDistrict = await axios.get(
        `https://oshwinwashing.com/api/sub_district/district/${idDistrict}`
      );
      try {
        setSubDistrict(optionsHandler(ressubDistrict.data.Data));
      } catch (e) {
        console.log(e);
      }
    }
    customerValues.district_id.value !== "" && getsubDistrict();
  }, [customerValues.district_id]);

  function optionsHandler(resData) {
    let options = [];
    resData.forEach((dt) => {
      options.push({
        value: dt.id,
        title: dt.name,
      });
    });
    return options;
  }
  function onChangeInput(event, objName) {
    let value = event.target.value;
    if (objName === "phone") {
      value = formatStringNumber(event.target.value);
    }
    setCustomerValues((prevState) => ({
      ...prevState,
      [objName]: {
        ...prevState[objName],
        value: value,
        status: false,
        errorText: "",
      },
    }));
    if (
      objName === "province_id" &&
      value !== customerValues.province_id.value
    ) {
      setCity([]);
      setDistrict([]);
      setSubDistrict([]);
      setCustomerValues((prevState) => ({
        ...prevState,
        city_id: {
          ...prevState.city_id,
          value: "",
        },
        district_id: {
          ...prevState.district_id,
          value: "",
        },
        sub_district_id: {
          ...prevState.sub_district_id,
          value: "",
        },
      }));
    }
    if (objName === "city_id" && value !== customerValues.city_id.value) {
      setDistrict([]);
      setSubDistrict([]);
      setCustomerValues((prevState) => ({
        ...prevState,
        district_id: {
          ...prevState.district_id,
          value: "",
        },
        sub_district_id: {
          ...prevState.sub_district_id,
          value: "",
        },
      }));
    }
    if (
      objName === "district_id" &&
      value !== customerValues.district_id.value
    ) {
      setSubDistrict([]);
      setCustomerValues((prevState) => ({
        ...prevState,
        sub_district_id: {
          ...prevState.sub_district_id,
          value: "",
        },
      }));
    }
  }
  async function onClickSubmit() {
    let valid = true;
    const objKeys = Object.keys(customerValues);
    objKeys.forEach((key) => {
      const validation = checkValidity(
        customerValues[key].value,
        customerValues[key].validation
        );
        valid = validation.valid && valid;
        setCustomerValues((prevState) => ({
          ...prevState,
          [key]: {
            ...prevState[key],
            status: validation.valid,
            errorText: validation.text,
          },
        }));
      });
    
    if (valid) {
      setLoading(true)
      setStatusResponse(null)
      const customerObj = {};
      const addressObj = {};
      objKeys.forEach((key) => {
        if (
          key === "province_id" ||
          key === "city_id" ||
          key === "district_id" ||
          key === "sub_district_id"
        ) {
          addressObj[key] = parseInt(customerValues[key].value);
        } else if (key === "line" || key === "postal_code") {
          addressObj[key] = customerValues[key].value;
        } else {
          customerObj[key] = customerValues[key].value;
        }
      });
      const dataCustomer = {
        ...customerObj, 
        ...(formEditMode && { id: data.id }),
        address: {
          ...addressObj, 
          country_id:1
        }}
      const responseCustomer = formEditMode
      ? updateDataAPI('/customer', dataCustomer)
      : postDataAPI('/customer', dataCustomer);
      await responseCustomer
      .then((res) => {
        console.log(res)
        if (res.status === 200) {
            setStatusResponse(true);
            setResponseMessage(res.data.Messages);
            setTimeout(() => {
              setStatusResponse(null);
              onClose();
              onSuccess(dataCustomer);
            }, 3000);
          }
        })
        .catch((error) => {
          console.log(error);
          setResponseMessage(error.response.data.Messages);
          if (error.response.status === 500) {
            setStatusResponse(false);
            setCustomerValues((prevState) => ({
              ...prevState,
              name: {
                ...prevState.name,
                errorText: error.response.data.errorCode === 1 ? "Nama sudah digunakan" : "",
                status: error.response.data.errorCode === 1 ? false : true,
              },
              phone: {
                ...prevState.phone,
                errorText: error.response.data.errorCode === 2 ? "Nomor Telephone sudah digunakan" : "",
                status: error.response.data.errorCode === 2 ? false : true,
              }
            }));
          }
          setTimeout(() => {
            setLoading(false)
          }, 3000);
        });
    }
  }
  return (
    <ModalForm
      title={formEditMode ? "Edit Customer" : "Add Customer"}
      onClose={onClose}
      showLoading={loading}
      responseMessages={responseMessage}
      responseStatus={statusResponse}
    >
      <Container>
        <Input
          inputStyle="input"
          title="Customer Name*"
          // placeholder='Ex: John Marks'
          value={customerValues.name.value}
          errorText={customerValues.name.errorText}
          onChange={(event) => onChangeInput(event, "name")}
        />
        <Input
            inputStyle="input"
            title="Email"
            type="email"
            // placeholder='John@example.com'
            value={customerValues.email.value}
            errorText={customerValues.email.errorText}
            onChange={(event) => onChangeInput(event, "email")}
          />
        <Wrapper>
          <Input
            inputStyle="input"
            title="Phone*"
            // placeholder='081xxxxxx'
            value={customerValues.phone.value}
            errorText={customerValues.phone.errorText}
            onChange={(event) => onChangeInput(event, "phone")}
          />
          <Input
            inputStyle="input"
            title="Other Phone"
            // placeholder='081xxxxxx'
            value={customerValues.other_phone.value}
            errorText={customerValues.other_phone.errorText}
            onChange={(event) => onChangeInput(event, "other_phone")}
          />
        </Wrapper>
        <H4>Address Details</H4>
        <Input
          inputStyle="input"
          title="Address*"
          // placeholder='Jl. line'
          value={customerValues.line.value}
          errorText={customerValues.line.errorText}
          onChange={(event) => onChangeInput(event, "line")}
        />
        <Wrapper>
          <Input
            inputStyle="select"
            title="Province*"
            options={provinces}
            value={customerValues.province_id.value}
            errorText={customerValues.province_id.errorText}
            onChange={(event) => onChangeInput(event, "province_id")}
          />
          <Input
            inputStyle="select"
            title="City*"
            options={city}
            value={customerValues.city_id.value}
            errorText={customerValues.city_id.errorText}
            onChange={(event) => onChangeInput(event, "city_id")}
            disabled={customerValues.province_id.value === '' ? true : false}
          />
        </Wrapper>
        <Wrapper>
          <Input
            inputStyle="select"
            title="District*"
            options={district}
            value={customerValues.district_id.value}
            errorText={customerValues.district_id.errorText}
            onChange={(event) => onChangeInput(event, "district_id")}
            disabled={customerValues.city_id.value === '' ? true : false}
          />
          <Input
            inputStyle="select"
            title="SubDistrict*"
            options={subDistrict}
            value={customerValues.sub_district_id.value}
            errorText={customerValues.sub_district_id.errorText}
            onChange={(event) => onChangeInput(event, "sub_district_id")}
            disabled={customerValues.district_id.value === '' ? true : false}
          />
        </Wrapper>
        <Wrapper width="100px">
          <Input
            inputStyle="input"
            title="Postal Code"
            value={customerValues.postal_code.value}
            errorText={customerValues.postal_code.errorText}
            onChange={(event) => onChangeInput(event, "postal_code")}
          />
        </Wrapper>
        <ButtonWrapper>
          {
            formEditMode && <Button width="100px" onClick={onClose}>Cancel</Button>
          }
          <Button width="100px" onClick={onClickSubmit}>
            {formEditMode ? 'Save' : 'Create new'}
          </Button>
        </ButtonWrapper>
      </Container>
    </ModalForm>
  );
}

export default FormCustomer;
