import React, { useEffect, useState } from "react";
import { FaImage, FaPlus, FaTrash } from "react-icons/fa";
import { MdOutlineStayCurrentLandscape } from "react-icons/md";
import styled from "styled-components";
import ModalForm from "../../components/ModalForm/ModalForm";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import { H3, Label, SectionTitle} from "../../components/UI/TextStyle";
import { colorTheme } from "../../constants/colors";
import { getDataAPI } from "../../utility/http";
import { formatInputCurrency, formatToIDR, formatIDRtoInt } from "../../utility/inputFormatter";
import { checkValidity } from "../../utility/inputValidation";

const RowDiv = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 15px;
  & Button {
    align-self: center;
    > svg {
      width: 12px;
      height: 12px;
    }
  }
`;

const ButtonWrapper = styled(RowDiv)`
  display: flex;
  justify-content: space-between;
  column-gap: 15px;
  align-self: flex-end;
`;

const InputImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
`;

const PreviewImage = styled.div`
  display: flex;
  height: 150px;
  width: 150px;
  justify-content: center;
  align-items: center;
  border: 1px solid ${colorTheme.secondary};
  background-color: ${colorTheme.secondary};
  border-radius: 5px;
  overflow: hidden;
  box-sizing: border-box;
  margin-right: 20px 20px 20px 0px;
  padding: 0;
  > svg {
    width: 50px;
    height: 50px;
    color: ${colorTheme.white};
    padding: 5px;
  }
  > img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
`;
const WrapperInputCategories = styled.div`
  display:flex;
  align-items: center;
  & Input{
    border-radius: 5px 0px 0px 5px;
    width: auto;
  }
  & select{
    width: fit-content;
    background-color: ${colorTheme.primary};
    color: ${colorTheme.secondary};
    border-radius: 0px 5px 5px 0px;
  }
`
const InputPicture = styled.div`
  display: flex;
  flex-direction: column;
  & Button{
    margin-top:5px;
    width: 100%;
  }
`

const WrapperInput = styled.div`
  display: flex;
  flex-direction: column;
  & div:nth-child(3) {
    height: 100%;
  }
  & TextArea {
    height: 100%;
  }
`
const SectionInput = styled.div`
  margin-bottom: 20px;
`

function FormItem({ data, onClose, itemData, dataEdit }) {
  const initialStateItem = {
    articleName: {
      value: "",
      status: false,
      errorText: "",
      validation: { required: true, notSame: true },
    },
    services: [
      {
        process: {
          value: "",
          status: false,
          errorText: "",
          validation: { required: true },
        },
        price: {
          value: "0",
          status: false,
          errorText: "",
          validation: { required: true },
        },
        editable: false,
      },
    ],
    qty: {
      value: "",
      status: false,
      errorText: "",
      validation: { required: true },
    },
    units: {
      value: 1,
      status: false,
      errorText: "",
      validation: { required: true },
    },
    note: {
      value: "",
      status: true,
      errorText: "",
      validation: {},
    },
    image: {
      value: "",
      status: true,
      errorText: "",
      validation: {},
    },
  };
  const [selectedImageItem, setSelectedImageItem] = useState(null);
  const [itemValues, setItemValues] = useState(initialStateItem);
  const [productsData, setProductData] = useState(null);
  const [optProduct, setOptProduct] = useState([]);
  const [fixedPrice, setFixedPrice] = useState(0);
  const [listItem, setListItem] = useState([]);
  const [optUnits, setOptUnits] = useState([]);
  const [editMode, setEditMode] = useState(false);

  // const optUnits = [
  //   { value: "pcs", title: "pcs" },
  //   { value: "lembar", title: "lembar" },
  //   { value: "meter", title: "meter" },
  //   { value: "yard", title: "yard" },
  // ];

  useEffect(() => {
    async function getProductData() {
      const response = await getDataAPI('/service?status=active');
      const resOptQty= await getDataAPI('/order_unit')
      const productOptions = response.data.Data.map((dt) => {
        return {
          value: parseInt(dt.id),
          title: dt.name,
        };
      });
      const unitsOptions = resOptQty.data.Data.map((dt) => {
        return {
          value: parseInt(dt.id),
          title: dt.name,
        };
      });
      setProductData(response.data.Data);
      setOptProduct(productOptions);
      setOptUnits(unitsOptions);
      if(dataEdit !== undefined){
        setEditMode(true)
        // const responseOrder = await getDataAPI('/')
      }else{
        setEditMode(false)
      }
    }
    getProductData();
  }, []);
  useEffect(()=>{
    function totalPrice(){
      if (itemValues.qty.value !== "") {
        let value = 0
        itemValues.services.forEach(dt => {
          value += formatIDRtoInt(dt.price.value)
        })
        setFixedPrice(value * itemValues.qty.value)
      }
    }
    totalPrice()
  },[itemValues])

  useEffect(()=>{
    data(listItem)
  },[listItem])

  function onChangeItem(event, key, idx) {
    let value = event.target.value;
    if (idx !== undefined) {
      const listProcess = [...itemValues.services];
      listProcess[idx][key].value = key === "price" ? formatInputCurrency(event.target.value) : event.target.value;
      listProcess[idx][key].status = false;
      listProcess[idx][key].errorText = "";
      if (key === "process") {
        const selectedProduct = productsData.find((key) => key.id == value);
        listProcess[idx].price.value = formatToIDR(selectedProduct.price);
        listProcess[idx].price.status = true;
        listProcess[idx].price.errorText = "";
        listProcess[idx].editable = false;
      }
      setItemValues((prevState) => ({
        ...prevState,
        services: listProcess,
      }));
    } else {
      setItemValues((prevState) => ({
        ...prevState,
        [key]: {
          ...prevState[key],
          value: value,
          status: false,
          errorText: "",
        },
      }));
    }
  }
  function toggleHandler(idx) {
    const arr = [...itemValues.services];
    const value = arr[idx].editable;
    arr[idx].editable = !value;
    const processVal = arr[idx].process.value
    const selectedProduct = productsData.find((key) => key.id == processVal);
    if(!value === false){
      arr[idx].price.value = selectedProduct !== undefined ? formatToIDR(selectedProduct.price) : formatToIDR(0)
    }
    setItemValues((prevState) => ({
      ...prevState,
      services: [...arr],
    }));
  }
  function addMoreServices() {
    setItemValues((prevState) => ({
      ...prevState,
      services: [...prevState.services, initialStateItem.services[0]],
    }));
  }
  function submitItem() {
    let isValid = true;
    Object.keys(itemValues).map((key) => {
      if (key === "services") {
        itemValues.services.map((dt, idx) => {
          const arr = [...itemValues.services];
          Object.keys(dt).map((val) => {
            if (val !== "editable") {
              const check = checkValidity(dt[val].value, dt[val].validation);
              isValid = check.valid && isValid;
              arr[idx][val].status = check.valid;
              arr[idx][val].errorText = check.text;
            }
          });
          setItemValues((prevState) => ({
            ...prevState,
            services: arr,
          }));
        });
      } else {
        const check = checkValidity(
          itemValues[key].value,
          itemValues[key].validation,
          listItem,
          "name"
        );
        isValid = check.valid && isValid;
        setItemValues((prevState) => ({
          ...prevState,
          [key]: {
            ...prevState[key],
            status: check.valid,
            errorText: check.text,
          },
        }));
      }
    });
    if (isValid) {
      let imgData = selectedImageItem === undefined ? null : selectedImageItem
      setListItem((prevState) => [
        ...prevState,
        {
          ...itemValues,
          image: imgData
        },
      ]);
      const arrService = itemValues.services.map(dt => ({
        service_id: dt.process.value,
        amount: dt.price.value
      }))
      let dataObj = {
        name: itemValues.articleName.value,
        quantity: parseInt(itemValues.qty.value),
        units: itemValues.units.value,
        note: itemValues.note.value,
        path_image: imgData,
        services: arrService
      }
      console.log(dataObj)
      itemData(dataObj)
      onCloseForm();
      onClose();
    }
  }
  function onCloseForm() {
    setItemValues(initialStateItem);
    setSelectedImageItem(null);
  }

  function disabledButton(){
    let isValid = true
    itemValues.services.map((dt) => {
      Object.keys(dt).map((val) => {
        if (val !== "editable") {
          const check = checkValidity(dt[val].value, dt[val].validation);
          isValid = check.valid && isValid;
        }
      });
    });
    return isValid
  }

  function deleteMoreServices(idx) {
    const listProcess = [...itemValues.services];
    listProcess.splice(idx, 1);
    setItemValues((prevState) => ({
      ...prevState,
      services: listProcess,
    }));
  }
  function onClickEditItem() {}
  return (
    <ModalForm
      title={editMode ? "Edit Item" : "Add Item"}
      onClose={onClose}
    >
      <SectionInput>
      <SectionTitle>Article Information</SectionTitle>
      <RowDiv>
      <InputPicture>
        <Label>Pictures</Label>
        <PreviewImage>
          {selectedImageItem !== null && selectedImageItem !== undefined ? (
            <img src={URL.createObjectURL(selectedImageItem)} alt="" />
            ) : (
              <FaImage />
              )}
        </PreviewImage>
        <Label><br/></Label>
        <Button>Choose Image</Button>
        {/* <InputImage>
          <Input
            inputStyle="file"
            title="Image (max file size 250KB)"
            name="Emp_Img"
            accept="image/png, image/jpeg, image/webp"
            errorText=""
            onChange={(event) => setSelectedImageItem(event.target.files[0])}
            />
        </InputImage> */}
      </InputPicture>
      <WrapperInput>
        <Input
          inputStyle="input"
          title="Article Name"
          value={itemValues.articleName.value}
          errorText={itemValues.articleName.errorText}
          onChange={(event) => onChangeItem(event, "articleName")}
          />
        <WrapperInputCategories>
          <Input
            inputStyle="input"
            type="number"
            title="Qty"
            options={optUnits}
            errorText={itemValues.qty.errorText}
            value={itemValues.qty.value}
            onChange={(event) => onChangeItem(event, "qty")}
            />
          <Input
            inputStyle="select"
            options={optUnits}
            value={itemValues.units.value}
            onChange={(event) => onChangeItem(event, "units")}
            />
        </WrapperInputCategories>
        <Input
          inputStyle="textArea"
          title="Note"
          value={itemValues.note.value}
          onChange={(event) => onChangeItem(event, "note")}
        />
      </WrapperInput>
      </RowDiv>
      </SectionInput>
      <SectionTitle>Services Details</SectionTitle>
      <SectionInput>
      {itemValues.services.map((dt, idx) => {
        return (
          <RowDiv key={idx}>
            <Input
              inputStyle="select"
              title="Process"
              options={optProduct}
              errorText={itemValues.services[idx]?.process.errorText}
              value={itemValues.services[idx]?.process.value}
              onChange={(event) => onChangeItem(event, "process", idx)}
              />
            <RowDiv>
              <Input
                inputStyle="toggle"
                title="Price"
                onChangeToggle={(e) => toggleHandler(idx, e)}
                toggleValue={itemValues.services[idx]?.editable}
                errorText={itemValues.services[idx]?.price.errorText}
                value={itemValues.services[idx]?.price.value}
                onChange={(event) => onChangeItem(event, "price", idx)}
                />
              {itemValues.services.length > 1 && (
                <Button btnStyle="icon" onClick={() => deleteMoreServices(idx)}>
                  <FaTrash />
                </Button>
              )}
            </RowDiv>
          </RowDiv>
        );
      })}
      <Button width='120px' disabled={!disabledButton()} onClick={addMoreServices}><FaPlus/>More Service</Button>
      </SectionInput>
      <Label>Total Price</Label>
      <H3>
        {formatToIDR(fixedPrice)}
      </H3>
      <ButtonWrapper>
        {!editMode ? (
          <Button onClick={submitItem} width="80px">
            Add Item
          </Button>
        ) : (
          <Button onClick={onClickEditItem} width="80px">
            Save
          </Button>
        )}
      </ButtonWrapper>
    </ModalForm>
  );
}

export default FormItem;
