import React, { useState, useEffect } from "react";
import styled from "styled-components";
import BoxWrapper from "../../components/BoxWrapper/BoxWrapper";
import FormProduct from "./FormProduct";
import TableStyled from "../../components/UI/TableStyled";
import Confirmation from "../../components/Modal/Confirmation";
import SliderRadio from "../../components/UI/SliderRadio";
import { deleteDataAPI, deleteProduct, filterDataAPI, getDataAPI, getProduct, patchDataAPI, patchProduct } from "../../utility/http";
import { formatToIDR } from "../../utility/inputFormatter";
import { H3 } from "../../components/UI/TextStyle";
import { FaPen, FaPlus, FaTrash } from "react-icons/fa";
import { colorTheme } from "../../constants/colors";
import InputClear from "../../components/UI/Input/InputClear";
import Button from "../../components/UI/Button";

const Container = styled(BoxWrapper)`
  flex-direction: column;
  & th {
    z-index: 2;
  }
  & th:nth-child(1) {
    width: fit-content;
  }
  & th:nth-child(2) {
    width: 25%;
  }
  & th:nth-child(5) {
    width: 35%;
  }
  & th:nth-child(6) {
    width: 7%;
  }
`;
const TR = styled.tr`
  width: 100%;
`;
const TD = styled.td`
  font-size: 12px;
  padding: 8px;
  > svg {
    width: 15px;
    margin-right: 5px;
    height: 15px;
    cursor: pointer;
    color: ${colorTheme.gray};
  }
`;

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
`;
function ProductAndServices() {
  const column = [
    { heading: "No", value: "no" },
    { heading: "Product Name", value: "name" },
    { heading: "Service Code", value: "service_code" },
    { heading: "Price", value: "price" },
    { heading: "Description", value: "description" },
    { heading: "Status", value: "is_actived" },
  ];
  const [showFormProduct, setShowFormProduct] = useState(false);
  const [productList, setProductList] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState({
    value: false,
    type: "",
  });
  const [dataInAction, setDataInAction] = useState(null);
  const [loadingModal, setLoadingModal] = useState(false);
  const [loadingStatusModal, setLoadingStatusModal] = useState(null);
  const [loadingTable, setLoadingTable] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [inputSearchValue, setInputSearchValue] = useState("");

  async function getProductData() {
    if (inputSearchValue.length > 0) {
      await filterDataAPI('service', inputSearchValue)
        .then((res) => {
          setProductList(res.data.Data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setLoadingTable(true);
      const response = await getDataAPI('/service?status=available');
      try {
        setProductList(response.data.Data);
        setTimeout(() => {
          setLoadingTable(false);
        }, 150);
      } catch (e) {
        setTimeout(() => {
          setLoadingTable(false);
        }, 150);
        console.log(e);
      }
    }
  }

  useEffect(() => {
    async function getFilterData() {
      const value = inputSearchValue;
      if (value.length > 0) {
        await getProductData("filter");
      } else {
        await getProductData();
      }
    }
    getFilterData();
  }, [inputSearchValue]);

  function onEditHandler(values) {
    setDataInAction(values);
    setShowFormProduct(true);
  }
  function onDeleteHandler(values) {
    setShowConfirmModal((prevState) => ({
      ...prevState,
      value: true,
      type: "delete",
    }));
    setDataInAction(values);
  }
  function afterAction() {
    setDataInAction(null);
    getProductData();
  }
  function onChangeSlider(value) {
    setShowConfirmModal((prevState) => ({
      ...prevState,
      value: true,
      type: "toggleStatus",
    }));
    setDataInAction(value);
  }

  async function confirmAction() {
    setLoadingModal(true);
    const response =
      showConfirmModal.type === "delete"
        ? await deleteDataAPI('/service/', dataInAction.id)
        : await patchDataAPI('/service/', dataInAction.id, !dataInAction.is_actived);
    try {
      setLoadingStatusModal(true);
      setResponseMessage(response.data.Messages);
      setTimeout(async () => {
        response.status === 200 && getProductData("");
        setLoadingStatusModal(null);
        setResponseMessage("");
        setShowConfirmModal((prevState) => ({
          ...prevState,
          value: false,
          type: "",
        }));
        setLoadingModal(false);
        setDataInAction(null);
      }, 1500);
    } catch (error) {
      setLoadingStatusModal(false);
    }
  }
  return (
    <>
      <FilterWrapper>
        <InputClear
          inputType="input"
          placeholder="Search Name of product"
          // onClickAdd={() => setShowFormProduct(true)}
          // onKeyDown={handleKeyDown}
          onClickClose={()=> setInputSearchValue('')}
          onChange={(e) => setInputSearchValue(e.target.value)}
        />
        <Button onClick={() => setShowFormProduct(true)}><FaPlus/>Add Service</Button>
      </FilterWrapper>
      <Container>
        <H3>Table Items</H3>
        <TableStyled
          column={column}
          actions={["delete", "edit"]}
          loading={loadingTable}
        >
          {productList?.map((dt, idx) => (
            <TR key={idx}>
              <TD>{idx + 1}</TD>
              {column.map((item, index) => {
                if (item.value === "is_actived") {
                  return (
                    <TD key={index}>
                      <SliderRadio
                        value={dt[item.value]}
                        onClickChange={() => onChangeSlider(dt)}
                      />
                    </TD>
                  );
                }
                if (item.value === "price") {
                  return <TD key={index}>{formatToIDR(dt[item.value])}</TD>;
                }
                if (item.value !== "no") {
                  return <TD key={index}>{dt[item.value]}</TD>;
                } else {
                  return null;
                }
              })}
              <TD>
                <FaPen onClick={() => onEditHandler({ ...dt })} />
                <FaTrash onClick={() => onDeleteHandler(dt)} />
              </TD>
            </TR>
          ))}
        </TableStyled>
      </Container>
      {showFormProduct && (
        <FormProduct
          data={dataInAction}
          onClose={() => {
            setDataInAction(null);
            setShowFormProduct(false);
          }}
          onSuccess={afterAction}
        />
      )}
      {showConfirmModal.value &&
        (showConfirmModal.type === "delete" ? (
          <Confirmation
            title="Delete this product ?"
            desc="By confirming this action will permanently remove it from database, you cannot undo this change"
            messages={responseMessage}
            loadingShow={loadingModal}
            loadingStatus={loadingStatusModal}
            onCancel={() =>
              setShowConfirmModal((prevState) => ({
                ...prevState,
                value: false,
                type: "",
              }))
            }
            onConfirm={confirmAction}
          />
        ) : (
          <Confirmation
            title={`Change this product to ${
              !dataInAction?.is_actived ? "Active" : "Inactive"
            } ?`}
            desc="By confirming this action will permanently change it from database, you cannot undo this change"
            messages={responseMessage}
            loadingShow={loadingModal}
            loadingStatus={loadingStatusModal}
            onCancel={() =>
              setShowConfirmModal((prevState) => ({
                ...prevState,
                value: false,
                type: "",
              }))
            }
            onConfirm={confirmAction}
          />
        ))}
    </>
  );
}

export default ProductAndServices;
