import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { colorTheme } from '../../constants/colors'
import Button from '../UI/Button'
import { H2, Text } from '../UI/TextStyle'
import Modal from './Modal'
import { ReactComponent as Attention } from '../../assets/icons/Attention.svg'
import Spinner from '../Loading/Spinner'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width:400px;
    border-radius:15px;
    background-color:${colorTheme.white};
    box-sizing: border-box;
    padding:50px;
    position: relative;
    overflow:hidden;
    > svg {
        width:85px;
        height:85px;
        margin-bottom:15px;
    }
    & H2, ${Text}{
        margin:15px;
        text-align: center;
    }`
const ButtonWrapper = styled.div`
    display: flex;
    & Button {
        margin:15px;
        &:hover {
            opacity:0.8;
        }
    }`

function Confirmation({ title, desc, onCancel, onConfirm, responseStatus, messages }) {
    const [loading, setLoading] = useState(false)
    async function onClickConfirm(){
        setLoading(true)
        await onConfirm()
    }
    return (
        <Modal>
            <Container>
                <Attention />
                <H2>{title}</H2>
                <Text>{desc}</Text>
                <ButtonWrapper>
                    <Button btnStyle='' onClick={onCancel}>Cancel</Button>
                    <Button btnStyle='secondary' onClick={onClickConfirm}>Yes</Button>
                </ButtonWrapper>
                {
                    loading && <Spinner responseStatus={responseStatus} messages={messages} />
                }
            </Container>
        </Modal>
    )
}

export default Confirmation