import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import BoxWrapper from '../../components/BoxWrapper/BoxWrapper'
import { colorTheme } from '../../constants/colors'
import {
    FaAngleDown,
    FaBox,
    FaBoxes,
    FaCheckCircle,
    FaDolly,
    FaExclamationCircle,
    FaFileInvoiceDollar,
    FaIndustry,
    FaPlus,
    FaTimesCircle,
    FaTruck,
    FaTruckLoading,
    FaUndoAlt,
  } from "react-icons/fa";
import StatusBox from '../../components/StatusBox/StatusBox';
import { getDataAPI } from '../../utility/http';
import { H3 } from '../../components/UI/TextStyle';
import Table from '../../components/UI/Table';
import { useNavigate } from 'react-router-dom';
import { formatToIDR } from '../../utility/inputFormatter';
import Input from '../../components/UI/Input';
import Button from '../../components/UI/Button';
import FilterBox from '../../components/FilterBox/FilterBox';
import SearchBar from '../../components/UI/SearchBar';
const Container = styled.div`
    display: flex;
    width:100%;
    flex-direction: column;
`
const TableWrapper = styled(BoxWrapper)`
    width: 100%;
    height: 100%;
    margin-top: 10px;
`
const StatusWrapper = styled(BoxWrapper)`
  display: flex;
  width: 100%;
  min-height: 80px;
  max-height: 90px;
  flex-direction: row;
  position: relative;
  padding: 20px 0px 0px 0px;
  gap: 0px 10px;
  background-color: transparent;
  & div:nth-child(8) {
    background-color: ${colorTheme.yellow};
  }
  & div:nth-child(9) {
    background-color: ${colorTheme.red};
  }
  & div:nth-child(10){
    background-color: ${colorTheme.white};
    & svg, p, h2 {
        color: ${colorTheme.primary};
    }
  }
`;
const Wrapper = styled.div`
  display: flex;
`;

const WrapperFilter = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* background-color:red; */
  & ${Wrapper} {
    gap: 5px;
    & svg:nth-child(1) {
      transition: transform 0.3s ease-in-out;
      transform: rotate(${(props) => (props.show ? "-180deg" : "0deg")});
    }
  }
`;

function Order() {
    const [selectedStatus, setSelectedStatus] = useState(0);
    const [statusData, setStatusData] = useState([]);
    const [tableOrder, setTableOrder] = useState([]);
    const [showFilter, setShowFilter] = useState(false);
    const navigate = useNavigate()
    const iconStatus ={
          1: FaDolly,
          2: FaIndustry,
          3: FaBox,
          4: FaTruck,
          5: FaTruckLoading,
          6: FaFileInvoiceDollar,
          7: FaCheckCircle,
          8: FaExclamationCircle,
          9: FaTimesCircle,
          0: FaBoxes,
        }
      const column = [
        { heading: "Order Number", value: "number" },
        { heading: "Customer", value: "customer_name" },
        { heading: "Production Type", value: "type" },
        { heading: "Total Article", value: "article_count" },
        { heading: "Amount", value: "amount" },
        { heading: "Date Order", value: "date" },
        { heading: "Order by", value: "order_by" },
        { heading: "Status", value: "status_name" },
      ];
    useEffect(()=>{
        async function getData(){
            try{
                const response = await getDataAPI('/order')
                const resStatusOrder = await getDataAPI('/order_status')
                setStatusData(resStatusOrder.data.Data)
                setTableOrder(response.data.Data)
              }
              catch (err){
                console.log(err)
            }
        }
        getData()
    },[])
    function showTableHandler(){
        if(tableOrder.length > 0){
          const tableValues = tableOrder?.map(dt => {
            let date = new Date(dt.date)
            return ({
              ...dt,
              date: date.toLocaleDateString('en-GB'),
              amount: formatToIDR(dt.amount)
            })
          })
          return tableValues
        }else{
          return []
        }
      }
    return (
        <Container>
          <WrapperFilter show={showFilter}>
            <Wrapper>
              <SearchBar
                onChangeInput={(e)=>{}}
                onClickClose={()=>{}}
                dropDown={false}
              />
              <Button onClick={() => setShowFilter(!showFilter)}>
                Filter <FaAngleDown />
              </Button>
            </Wrapper>
            <Button onClick={() => navigate("/order/new")}>
              <FaPlus /> New Order
            </Button>
          </WrapperFilter>
          <FilterBox show={showFilter}>
            {/* <Input title="Order Number" inputStyle="input"></Input> */}
            {/* <Input title="Customer Name" inputStyle="input"></Input>
            <Input title="Article Name" inputStyle="input"></Input>
            <Input title="Date From" inputStyle="input"></Input>
            <Input title="Date To" inputStyle="input"></Input>
            <Input title="Status Order" inputStyle="input"></Input> */}
            <Button>Search</Button>
          </FilterBox>
        <StatusWrapper>
          {statusData?.map((dt, index) => {
            return (
              <StatusBox
                key={index}
                Icon={iconStatus[dt.id]}
                title={dt.name}
                number={dt.total}
                desc={dt.description}
                onClick={() => {
                  setSelectedStatus(index);
                }}
                active={selectedStatus === index}
              />
            );
          })}
        </StatusWrapper>
        <TableWrapper>
            <H3>Table Order</H3>
            <Table
              column={column}
              data={showTableHandler(tableOrder)}
              onClickRow={(dt)=> navigate(`number/${dt.number}`,{state: dt})}
              // actions={["delete", "edit"]}
            //   loading={loadingTable}
              // onClickDelete={(e) => handlerDelete(e)}
              // onClickEdit={(e) => handlerEdit(e)}
            />
            
          </TableWrapper>
    </Container>
  )
}

export default Order