import { configureStore } from "@reduxjs/toolkit";
import layoutReducer from './layout'
import orderReducer from './order'

const store = configureStore({
    reducer: {
        layout: layoutReducer,
        order: orderReducer
    }
})

export default store